import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
} from "../../comman/function";
import API from "../../constants/API";
import { authHeader, getAlertToast, getAlert } from "../../comman/mainfunction";
import { RESEND_EMAIL, UPDATE_SUPPLIER_STATUS } from "../../comman/url";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SuppliersentInvitationModal = ({
  open,
  addToggleClose,
  successReqInviteSupplierCallBack,
  requestListing,
  resendTo,
  requestId,
  deleteSuccessCallback,
}) => {
  const styleclasses = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const close = () => {
    addToggleClose();
  };
  const resendEmailApi = async () => {
    if (resendTo) {
      CustomMessage("Loading....", "Success", enqueueSnackbar);
      const config = {
        method: "POST",
        url: RESEND_EMAIL,
        headers: authHeader(),
        data: {
          webString: enCryptFun(
            JSON.stringify({
              ...resendTo,
            })
          ),
          flutterString: "",
        },
      };
      try {
        const response = await API(config);
        //const { status } = response.data;
        let l = deCryptFun(response.data);
        const { status, message } = JSON.parse(l);
        if (status === "Success") {
          UpdateStatus();
        }
      } catch (error) {
        if (error.response) {
          let { data } = error.response;
          let p = deCryptFun(data);
          let v = JSON.parse(p);
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const UpdateStatus = async () => {
    setLoading(true);
    const configsupplier = {
      method: "PUT",
      url: UPDATE_SUPPLIER_STATUS,
      headers: authHeader(),
      data: {
        // requestId: supplierId,
        // status: requeststatus == "Deactivated" ? "Accepted" : "Deactivated",
        webString: enCryptFun(
          JSON.stringify({
            requestId: requestId,
            status: "Pending",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configsupplier);
      // const { status, data, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, message } = JSON.parse(l);
      if (status === "Success") {
        CustomMessage(
          "An invite has been sent to the supplier",
          "success",
          enqueueSnackbar
        ); //call toster
        close();
        deleteSuccessCallback();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={close}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Do you wish to send the email?
        </BootstrapDialogTitle>
        <Box>
          {/* <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
         
          </DialogContent> */}
          <DialogActions>
            <DialogActions>
              <Button
                onClick={addToggleClose}
                className={styleclasses.canceldialog}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={styleclasses.submitdialog}
                onClick={resendEmailApi}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default SuppliersentInvitationModal;
