import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Divider, IconButton, Typography } from "@mui/material";
import { REPORT_URL, USER_EMAIL_LIST_URL } from "../comman/url";
import API from "../constants/API";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import SettingsTable from "./SettingsTable";
import Settingsdatatable from "./Settingsdatatable";
import Invoiceamounttable from "./Invoiceamounttable";
import Advancemetrixfilter from "../components/Modal/Advancemetrixfilter";
import { AdvanceInvoiceAmountTable } from "./AdvanceInvoiceAmountTable";
import { Invoicereceivedtable } from "./Invoicereceivedtable";
import { InvoiceAddressTable } from "./InvoiceAddressTable";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { InvoiceRoundedAmountTable } from "./InvoiceRoundedAmountTable";
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },

  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  });
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));

export default function Metadvancetable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { classes } = props;
  const styleclasses = useStyles();

  const [nameData, setNameData] = useState([]);

  const [metricType, setMetricType] = useState("");
  const [supplierData,setSupplierData] = useState([])

  const handleMetricTypeChange = (event) => {
    setMetricType(event.target.value);
  };

  //saveforms.
  let GetMember = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: USER_EMAIL_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            entityType: "Supplier",
            offset: 0,
            count: 1000,
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, data, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, count } = JSON.parse(l);
      if (status === "Success") {
        setNameData(data);
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    GetMember();
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", mb: 1 }}>
        <Grid item xs={12} md={5} lg={5} sx={{ display: "flex" }}>
          <Grid item xs={7} sx={{ margin: "0px 8px 16px 0px" }}>
            <Typography
              className={styleclasses.preferreddate}
              sx={{ fontWeight: 600, pb: 1, textAlign: "start" }}
            >
              Select the metric type:
            </Typography>
            <FormControl fullWidth>
              <Select
                value={metricType}
                onChange={handleMetricTypeChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {[
                  "Invoice Amount",
                  "Invoices Received",
                  "Invoice Rounded Amount",
                  // "Invoice Address",
                ].map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {metricType === "" ? (
        <Typography>Select the metric type</Typography>
      ) : null}
      {metricType === "Invoice Amount" ? (
        <AdvanceInvoiceAmountTable nameData={nameData} setSupplierData={setSupplierData}/>
      ) : null}
      {metricType === "Invoices Received" ? (
        <Invoicereceivedtable nameData={nameData} supplierData={supplierData}/>
      ) : null}
      {metricType === "Invoice Rounded Amount" ? (
        <InvoiceRoundedAmountTable nameData={nameData} />
      ) : null}
      {/* {metricType === "Invoice Address" ? (
        <InvoiceAddressTable nameData={nameData} />
      ) : null} */}
    </Box>
  );
}
