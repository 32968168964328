import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import {
  DASHBOARD_ROUTE,
  SIGNIN_ROUTE,
  FORGOTPASSWORD_ROUTE,
  SIGNUP_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  AUTHENTIFICATION_ROUTE,
  REGISTERED_ROUTE,
  VERIFY_EMAIL_ROUTE,
} from "./constants/RoutePaths";
import Signin from "./components/Signin";
import Layouts from "./components/Layouts";
import Forgotpassword from "./components/Forgotpassword";
import Authentification from "./components/Authentification";
import Signup from "./components/Signup";
import NotFound from "./components/NotFound";
import { PrivateRoute, PublicRoute } from "./comman/mainfunction";
import Updatepassword from "./components/Updatepassword";
import Registered from "./components/Registered";
import VerifyEmail from "./components/VerifyEmail";

function App() {

  const token = localStorage.getItem("AUTH_TOKEN");

  return (
    <div className="App">
      <Routes>
        <Route index path="/" element={token ? <Navigate to={DASHBOARD_ROUTE} /> : <Navigate to={SIGNIN_ROUTE} />}></Route>
        <Route index path="/*" element={<PrivateRoute><Layouts /></PrivateRoute>}></Route>
        <Route path={SIGNIN_ROUTE} element={<PublicRoute><Signin /></PublicRoute>}></Route>
        <Route path={REGISTERED_ROUTE} element={<Registered />}></Route>
        <Route path={VERIFY_EMAIL_ROUTE} element={<VerifyEmail />}></Route>
        <Route path={FORGOTPASSWORD_ROUTE} element={<PublicRoute><Forgotpassword /></PublicRoute>}></Route>
        <Route path={AUTHENTIFICATION_ROUTE} element={<PublicRoute><Authentification /></PublicRoute>}></Route>
        <Route path={UPDATE_PASSWORD_ROUTE} element={<Updatepassword />}></Route>
        <Route path={SIGNUP_ROUTE} element={<PublicRoute><Signup /></PublicRoute>}></Route>
        <Route path="pagenotfound" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
