import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import TabPanel from "@mui/lab/TabPanel";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TabContext from "@mui/lab/TabContext";
import Apptomate from "../images/apptomate.png";
import EditProfile from "./Profile/EditProfile";
import { Grid, Stack } from "@mui/material";
import SecurityProfile from "./Profile/SecurityProfile";
import Companyinfo from "./Profile/Companyinfo";
import { useSelector } from "react-redux";
import { getRole } from "../comman/function";

const useStyles = makeStyles((theme) => ({}));

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "14px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const Input = styled("input")({
  display: "none",
});
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export default function Profile(props) {
  const { classes } = props;
  const styleclasses = useStyles();
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const [value, setValue] = React.useState("1");

  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" sx={{ fontWeight: 500, fontSize: "30px" }}>
            Manage Profile
          </Typography>
        </Grid>
        <Grid item sx={{paddingTop: '0 !important'}} xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            <img
              src={TeamReducer.companyLogo}
              alt=""
              className="companyLogo"
            ></img>
          </Stack>
        </Grid>
      </Grid>{" "}
      <Box
        sx={{
          backgroundColor: "#fff",
          mt: 2,
          padding: "15px 25px",
          borderRadius: "10px",
          boxShadow: "2px 2px 9px #0000001c",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Grid item xs={12} md={6} lg={6} sx={{ display: "flex" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ bgcolor: "none" }}>
                  <AntTabs
                    sx={{ borderBottom: "none" }}
                    value={value}
                    onChange={handletabChange}
                    aria-label="ant example"
                  >
                    <AntTab
                      label="Account Settings"
                      value="1"
                      style={{ fontWeight: 500 }}
                    />
                    <AntTab
                      label="Account Security"
                      value="2"
                      style={{ fontWeight: 500 }}
                    />
                    {getRole() != "Exception Handler" &&
                    getRole() != "Team Member" ? (
                      <AntTab
                        label="Company Details"
                        value="3"
                        style={{ fontWeight: 500 }}
                      />
                    ) : null}
                  </AntTabs>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TabContext value={value}>
          <TabPanel value="1">
            <EditProfile />
          </TabPanel>
          {/* Security */}
          <TabPanel value="2">
            <SecurityProfile />
          </TabPanel>

          {/* company Info */}
          <TabPanel value="3">
            <Companyinfo />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
