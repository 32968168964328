import React, { useState } from "react";
import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Mainlogo from "../images/Logo_Color1.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { FORGET_PASSWORD } from "../comman/url";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SIGNIN_ROUTE, SIGNUP_ROUTE } from "../constants/RoutePaths";
import { authHeader } from "../comman/mainfunction";
import { deCryptFun, enCryptFun } from "../comman/function";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { CustomMessage } from "../comman/helperfunction";
import ForgetPasswordResponse from "./Modal/ForgotPasswordResponse";

const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 40px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
    maxWidth: '440px',
    margin: 'auto'
  },
  otpbtn: {
    margin: "24px 0px 0px 0px !important",
    backgroundColor: "#EC7C37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    // "&:hover": {
    //   color: "#EC7C37 !important",
    //   border: "1px solid #EC7C37 !important",
    //   backgroundColor: "transparent !important",
    //   boxShadow: "none !important",
    // },
  },
}));

const Input = styled("input")({
  display: "none",
});

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F0F1F5",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

function Forgotpassword() {
  const styleclasses = useStyles();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setloading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit(values) {
        ForgetPassword(values);
      },
    });

  const ForgetPassword = async (values) => {
    setloading(true);
    const config = {
      method: "PUT",
      url: FORGET_PASSWORD,
      headers: authHeader(),
      // data: values,
      data: {
        webString: enCryptFun(JSON.stringify(values)),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        localStorage.setItem("EMAIL", values.email);
        setModalContent(
          "Your new password has been sent to your registered email address");
        setModalOpen(true);
        // CustomMessage(
        //   "Your new password has successfully been sent to your registered email address.",
        //   "success",
        //   enqueueSnackbar
        // ); //call toster
        // history(SIGNIN_ROUTE);
      } else {
        CustomMessage("Invalid Data", "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(message);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call tost
      }
    } finally {
      setloading(false);
    }
  };

  const gotTosigin = () => {
    history(SIGNIN_ROUTE);
  };

  const goTosiginup = () => {
    history(SIGNUP_ROUTE);
  };

  const closeToggle = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box container component={"form"} onSubmit={handleSubmit} sx={{ width: "100%", height: "100vh" }}>
          <Grid sx={{ margin: "auto" }} xs={12} md={4}>
            <Box>
              <img
                src={Mainlogo}
                style={{ marginTop: "30px", marginBottom: "20px" }}
              ></img>
            </Box>
            <Box className={styleclasses.loginbox}>
              <Typography
                sx={{ mt: 1, mb: 3, fontWeight: "600" }}
                align="center"
                variant="h5"
              >
                Forgot Password
              </Typography>
              <Typography align="center" paragraph color="gray">
                Enter the email address associated with your account and we'll
                send you a link to reset your password.
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Email
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={"text"}
                  error={errors.email && touched.email}
                  onBlur={handleBlur}
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  name="email"
                />
                <FormHelperText error={true}>
                  {errors.email && touched.email ? errors.email : ""}
                </FormHelperText>
              </FormControl>
              {/* <Box sx={{ textAlign: "right" }}>
                <Link
                  sx={{
                    mt: 2,
                    mb: 3,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                  color="#3779EC "
                  component="button"
                  variant="body2"
                  onClick={gotTosigin}
                >
                  Sign in
                </Link>
              </Box> */}
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  className={styleclasses.otpbtn}
                >
                  Reset
                </Button>
              </Box>
            </Box>
            <Typography sx={{ mt: 2 }}>
              Don't have an account?
              <Link
                sx={{ ml: 1, fontWeight: "600", textDecoration: "none" }}
                color="#793ED6"
                component="button"
                variant="body2"
                onClick={goTosiginup}
              >
                Sign Up
              </Link>
            </Typography>
          </Grid>
      </Box>
      <ForgetPasswordResponse
        toggleOn={modalOpen}
        closeToggle={closeToggle}
        content={modalContent}
      />
    </>
  );
}

export default Forgotpassword;
