import Axios from "axios";
const API = Axios.create();
API.interceptors.response.use(
  (response) => {
    let apitime = new Date(localStorage.getItem("API_TIME")) || new Date();
    var endDate = new Date();
    var seconds =
      (endDate.getTime() - (apitime?.getTime() || new Date())) / 1000;

    

    if (60 * 60 < seconds) {
      localStorage.clear();
      if (!window.location.href.includes("signin")) {
        window.location.replace("/signin");
      }
    } else {
      localStorage.setItem("API_TIME", new Date());
    }
    return response;
  },
  (error) => {
    const { status, data } = error.response;
    if (status === 401) { 
      localStorage.clear();
      if (!window.location.href.includes("signin")) {
        window.location.replace("/signin");
      }
    }
    return Promise.reject(error);
  }
);
export default API;
