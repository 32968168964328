import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Button, Divider, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import API from "../constants/API";
import Swal from "sweetalert2";
import { getAlert, getAlertToast } from "../comman/mainfunction";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
} from "../comman/function";
import Axios from "axios";
import { authHeader } from "../comman/mainfunction";
import {
  SUPPLIER_LISTING,
  UPDATE_SUPPLIER_STATUS,
  ACCESS_S3_FILE,
} from "../comman/url";
import { NoRecordFound } from "../components/NoRecordFound";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Badge } from "reactstrap";
import {
  requestStatusStyleConfig,
  statusStyleConfig,
} from "../constants/HelperConstant";
import Loader from "../comman/Loader";
import SupplierResentInvitationModal from "../components/Modal/SupplierResentInvitation";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tabledownloadbtn: {
    margin: "12px 0px 0px 8px !important",
    fontSize: "14px !important",
    color: "#EC7C37 !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    border: "1px solid #EC7C37 !important",
    "&:hover": {
      // backgroundColor: "#D0580F !important",
      // color: "white !important",
      border: "1px solid #EC7C37",
      backgroundColor: "white !important",
    },
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  resendby: {
    textTransform: "none !important",
    "&:hover": {
      background: "unset !important",
    },
  },

  tableContainerbg: {
    background: "white !important",
    // borderRadius: "4px !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
    overflow: "hidden",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  

  let a_value = a[orderBy];
  let b_value = b[orderBy];
  if (orderBy === "supplierName" || orderBy === "supplierCompanyName") {
    a_value = a_value?.toLowerCase();
    b_value = b_value?.toLowerCase();
  }

  if (b_value < a_value) {
    return -1;
  }
  if (b_value > a_value) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "supplierName",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
    sort: false,
  },
  {
    id: "download",
    numeric: true,
    disablePadding: false,
    label: "Download",
    sort: true,
  },
];
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 13,
//   },
// }));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    if (property !== "enable") {
      onRequestSort(event, property);
    }
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow style={{borderBottom: "1px solid black"}}>
        {headCells.map((headCell) => (
          <TableCell
            className={headCell.label !== "Enable" && "custom-border"}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.label === "Enable"}
              disabled={headCell.sort}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
              {headCell.label === "Enable" && (
                <Tooltip
                  title="Enable/Disable"
                  placement="bottom-start"
                  arrow
                  classes={{
                    arrow: styleclasses.arrow,
                    tooltip: styleclasses.tooltip,
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      color: "#A0A3BD",
                      width: "15px",
                      padding: "0px 1px",
                    }}
                  />
                </Tooltip>
              )}
            </TableSortLabel>
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function SupplierTablesecond({
  tableData,
  // requestListing,
  deleteSuccessCallback,
  tableLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [enableLoading, setEnableLoading] = useState(false);
  const [resendModal, setResendModal] = useState(false);
  const [resendTo, setResendTo] = useState({
    companyName: "",
    supplierEmail: "",
    requestedName: "",
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.suppliername);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const deleteRequest = async (supplierId, requeststatus) => {
    setEnableLoading(true);
    const configsupplier = {
      method: "PUT",
      url: UPDATE_SUPPLIER_STATUS,
      headers: authHeader(),
      data: {
        // requestId: supplierId,
        // status: requeststatus == "Deactivated" ? "Accepted" : "Deactivated",
        webString: enCryptFun(
          JSON.stringify({
            requestId: supplierId,
            status: requeststatus == "Deactivated" ? "Accepted" : "Deactivated",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configsupplier);
      // const { status, data, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, message } = JSON.parse(l);
      if (status === "Success") {
        CustomMessage(message, "success", enqueueSnackbar); //call toster

        deleteSuccessCallback();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setEnableLoading(false);
    }
  };

  const onClickDeleteRequest = async (id, requeststatus) => {
    if (id !== null && requeststatus !== null) {
      Swal.fire({
        title: "Are you sure?",
        text:
          requeststatus == "Deactivated"
            ? "Do you want to activate?"
            : "Do you want to deactivate?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          deleteRequest(id, requeststatus);
        }
      });
    }
  };
  const resendEmail = (data) => {
    const { companyName, supplierEmail, requestedName } = data;

    setResendTo((val) => ({
      ...val,
      companyName: companyName,
      supplierEmail: supplierEmail,
      requestedName: requestedName,
    }));

    setResendModal(true);
  };
  const closeResendEmail = () => {
    setResendModal(false);
  };

  var fileDownload = require("js-file-download");

  let Download = async (e) => {
    const urlfromSelection = e.currentTarget.dataset.url;
    
    if (urlfromSelection) {
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: {
          filePath: urlfromSelection,
        },
        headers: authHeader(),
      };
      try {
        const response = await API(config);
        const { status, url } = response.data;
        
        if (status === "Success") {
          Axios.get(url, {
            responseType: "blob",
          }).then((res) => {
            
            fileDownload(res.data, "supplier.pdf");
          });
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        // Swal.fire(getAlertToast("Error", message));
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } else {
      // Swal.fire(getAlertToast("error", "No file is found!"));
      CustomMessage("No file is found!", "error", enqueueSnackbar); //call toster
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "12px" }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className={styleclasses.tableContainerbg}>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
              />

              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {tableLoading && (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}

                {!tableLoading && tableData.length == 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}

                {!tableLoading &&
                  stableSort(tableData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.requestId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          // onClick={(event) =>
                          //   handleClick(event, row.requestId)
                          // }
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            
                            {row.supplierName}
                          </TableCell>
                          {/* <TableCell align="center">
                            {row.senderEmail}
                          </TableCell> */}
                          <TableCell
                            align="center"
                            style={{ fontWeight: 450, fontSize: 14 }}
                          >
                            <Button
                              className={styleclasses.tabledownloadbtn}
                              variant="outlined"
                              data-url={row.filePath}
                              style={{
                                borderRadius: 50,
                              }}
                              onClick={Download}
                            >
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
