import React, { useState, useEffect } from "react";

import Select from "react-select";
import MatrialuiSelect from "@mui/material/Select";
import makeAnimated from "react-select/animated";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_EXCEPTION_HANDLER_ROLE_URL,
  GET_EXCEPTION_HANDLER_DETAILS,
  SAVE_INVOICE_EXCEPTION,
  SAVE_NOTIFICATION_DETAILS,
  UPDATE_INVOICE_STATUS,
  BASE_API_URL,
  CREATE_COMMENT_URL,
  SAVE_AUDIT,
} from "../../comman/url";
import {
  INVOICEPREVIEW_ROUTE,
  INVOICE_ROUTE,
} from "../../constants/RoutePaths";
import {
  Name,
  getTeamID,
  getUserId,
  stableSort,
  enCryptFun,
  deCryptFun,
  getEmail,
} from "../../comman/function";
import API from "../../constants/API";
import Swal from "sweetalert2";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import { CustomMessage } from "../../comman/helperfunction";
import { getNotificationDetails } from "../../redux/action";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: "100px",
  },
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose,apiCall, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
         {!apiCall && <CloseIcon />}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

function ExceptionHandlerModel(props) {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const styleclasses = useStyles();
  const dispatch = useDispatch();
  const {
    exceptionFlagOn,
    exceptionaFlagOff,
    invoiceId,
    screenDisable,
    status,
    raisedTo,
    unlockInvoice,
    exceptionComments,
  } = props;
  const history = useNavigate();
  const urlOfInvoice =
    window.location.origin + INVOICEPREVIEW_ROUTE + "/" + invoiceId;
  const [handlerRoles, setHandlerRoles] = useState([]);
  const [handlerUserList, setHandlerUserList] = useState([]);
  const [apiParams, seApiParams] = useState({});
  const [exceptionTypeChange, setExceptionTypeChange] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [apiCall,setapiCall]=useState(false)
  const validationSchema = Yup.object({
    handlerType: Yup.string().required("required"),
    comments: Yup.string().required("required"),
  });
  const animatedComponents = makeAnimated();
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {
      handlerType: "",
      comments:["Request Information",
     "Supplier Maintenance",
      "Specialist Exception",               
      "Reject Invoice",
       "Duplicate" ].includes(status) ? exceptionComments:"",
      handlerUsers: [],
    },
    //validationSchema,
    onSubmit: (values) => postToHandler(values),
  });

  const { handlerType = "", handlerUsers = [], comments = "" } = values;

  //API CALL
  const getExceptionHandlerRoles = async () => {
    const config = {
      method: "GET",
      url: GET_EXCEPTION_HANDLER_ROLE_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            role: "role",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let response_decrypt = deCryptFun(response.data);
      const { data, status } = JSON.parse(response_decrypt);
      if (status == "Success") {
        setHandlerRoles(data);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const getExceptionHandlerDetails = async (selectedhandlerType) => {
    const config = {
      method: "GET",
      url: GET_EXCEPTION_HANDLER_DETAILS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            exceptionRoleId: selectedhandlerType,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let response_decrypt = deCryptFun(response.data);
      const { data, status } = JSON.parse(response_decrypt);
      if (status == "Success") {
        setHandlerUserList(
          data.map((listOfusers, i) => {
            const { exceptionDetailId, userName, userId } = listOfusers;
            let list = { value: userId, label: userName };
            return list;
          })
        );
      }
    } catch (error) {}
  };

  const saveExceptionInvoice = async () => {
    setapiCall(true)
    let userData = handlerUsers;
    let userData1 = selectedUser;  
    if (
      raisedTo &&
      raisedTo.length !== 0 &&
      handlerUsers &&
      handlerUsers.length !== 0
    ) {
      userData = userData.filter(
        (user) => !raisedTo.includes(user.value?.toString())
      );
    }
    
    // let responseStatus=status

    var roleid = handlerType
      ? handlerType
      : !exceptionTypeChange
      ? statusType(status)
      : "";
    if (roleid == "2" && localStorage.getItem("Suppliermaintanceemail") == "") {
      CustomMessage(
        "The Supplier Maintenance Email must be added in Administration before you can use this exception type",
        "error",
        enqueueSnackbar
      );
    } else {
      const config = {
        method: "POST",
        url: SAVE_INVOICE_EXCEPTION,
        headers: authHeader(),
        data: {
          webString: enCryptFun(
            JSON.stringify({
              invoiceId: invoiceId,
              teamId: getTeamID(),
              userId: userData1 ? userData1 && userData?.map((valueuser) => valueuser.value).join(",") : userData?.map((valueuser) => valueuser.value).join(","),
              raisedBy: getUserId(),
              comments: comments,
              exceptionRoleId: handlerType
                ? handlerType
                : !exceptionTypeChange
                ? statusType(status)
                : "",
              hyperLink: urlOfInvoice,
            })
          ),
          flutterString: "",
        },
      };
      try {
        let response = await API(config);
        let response_decrypt = deCryptFun(response.data);
        const { data, status } = JSON.parse(response_decrypt);
        if (status == "Success") {
       
          
          if (
            ![
              "Reject Invoice",
              "Supplier Maintenance",
              "Specialist Exception",
              "Request Information",
            ].includes(props.status)
          ) {
            updateInvoiceStatus();
            // statusType(status)
          } else {
            postComment();
            // navigate(INVOICE_ROUTE);
            unlockInvoice()
          }

        
          saveNotification(
            userData.map((valueuser) => valueuser.value).join(",")
          );
          saveAudit("Exception", false);
          screenDisable(true);
          // Swal.fire(getAlertToast("Success", "Exception Raised"));
          CustomMessage("Creating exception...", "success", enqueueSnackbar); //call toster
        }
      } catch (error) {
        setapiCall(false)
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const saveAudit = async (comments1, isExceptionResolved1) => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceId,
            comments: comments1,
            actionBy: getUserId(),
            isExceptionResolved: isExceptionResolved1,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      
    } catch (error) {}
  };
  const saveNotification = async (userid) => {
    const keycongigure = {
      method: "POST",
      url: SAVE_NOTIFICATION_DETAILS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            notificationDetailsId: 0,
            userId: userid,
            tag: "Exception",
            payload: "You have been assigned to an exception on this invoice",
            invoiceId: invoiceId,
            isRead: true,
            commentId: 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);
      
      dispatch(getNotificationDetails);
    } catch (error) {
      
    } finally {
      dispatch(getNotificationDetails());
    }
  };

  const postComment = async () => {
    const config = {
      method: "POST",
      url: CREATE_COMMENT_URL,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            postedBy: getUserId(),
            comment: comments,
            tagBy: "",
            invoiceId: invoiceId,
            exceptionComments: "@Invoice Exception Comments",
            isSupplierTab:1
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
    
      if (status === "Success") {
        CustomMessage("Exception Raised", "success", enqueueSnackbar); //call toster	
        setapiCall(false)	
          exceptionaFlagOff();	
         navigate(INVOICE_ROUTE);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  const updateInvoiceStatus = async () => {
    
    const config = {
      method: "PUT",
      url: UPDATE_INVOICE_STATUS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
            invoiceId: invoiceId,
            status: getExceptionRole(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        //Swal.fire(getAlertToast("Success", "Approved Successfully"));
        postComment();
        unlockInvoice()
        // navigate(INVOICE_ROUTE);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  useEffect(() => {
    getExceptionHandlerRoles();
  }, [raisedTo]);

  useEffect(() => {
    if (handlerType) {
      getExceptionHandlerDetails(handlerType);
    } else if (status && !exceptionTypeChange) {
      getExceptionHandlerDetails(statusType(status));
    }
  }, [handlerType, status]);

  const postToHandler = (values) => {
    saveExceptionInvoice();
  };
  const getExceptionRole = () => {
    
    
      
    let roletoStatus = handlerRoles.find(
      (role) => role.exceptionRoleId == handlerType
    )?.exceptionRole;
    
    return roletoStatus;
  };

  const multiSelectHandler = (value) => {
    //   
    //     let selectedUsers = value.map((data, i) => {
    //         return data.value
    //     })
    //     let selectedUserFormat = selectedUsers.toString()
    //     
    if(value){
      setSelectedUser('')
    }
    setFieldValue("handlerUsers", value);
  };

  const handleChangeType = (e) => {
    if (e.target.value === "") {
      setHandlerUserList([]);
    }
    handleChange(e);

    setFieldValue("handlerUsers", []);
    setExceptionTypeChange(true);
  };
  const statusType = (status) => {
    if (status == "Request Information") {
      return 1;
    } else if (status == "Supplier Maintenance") {
      return 2;
    } else if (status == "Specialist Exception") {
      return 3;
    } else if (status == "Reject Invoice") {
      return 4;
    } else if (status == "Duplicate") {
      return 5;
    }
     else {
      return "";
    }
  };

  useEffect(() => {
    let data = [];
    if (
      raisedTo &&
      raisedTo.length !== 0 &&
      handlerUserList &&
      handlerUserList.length !== 0
    ) {
      raisedTo.map((item) => {
        handlerUserList &&
          handlerUserList.map((list) => {
            if (list.value == item) {
              const { value, label } = list;
              data.push({ value: value, label: label });
            }
          });
      });
    }

    setSelectedUser(data);
  }, [raisedTo, handlerUserList]);
  return (
    <BootstrapDialog
      //onClose={exceptionaFlagOff}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={exceptionFlagOn}
      classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={exceptionaFlagOff}
        apiCall={apiCall}
      >
        Assign to Exception Handler
      </BootstrapDialogTitle>
      <Box component="form" form="true" onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography for="exceptionType">Select Exception Type</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <MatrialuiSelect
                  type="select"
                  name="handlerType"
                  id="handlerType"
                  onBlur={handleBlur}
                  onChange={handleChangeType}
                  value={
                    handlerType
                      ? handlerType
                      : !exceptionTypeChange
                      ? statusType(status)
                      : ""
                  }
                  disabled={
                    status == "Request Information" ||
                    status == "Supplier Maintenance" ||
                    status == "Specialist Exception" ||
                    status == "Reject Invoice" ||
                    status == "Duplicate"
                      ? true
                      : false
                  }
                  // value={handlerType}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select Exception Type</MenuItem>
                  {handlerRoles.map((role, i) => {
                    const { exceptionRoleId, exceptionRole } = role;
                    return (
                      <MenuItem
                        key={i}
                        value={exceptionRoleId}
                        // value={exceptionRole}
                      >
                        {exceptionRole}
                      </MenuItem>
                    );
                  })}
                </MatrialuiSelect>
                <FormHelperText>
                  {errors.handlerType && touched.handlerType
                    ? errors.handlerType
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography for="exceptionUser">Assign to User(s)</Typography>
            </Grid>
            <Grid item xs={12}>
              <Select
                className="selectusermodel"
                fullWidth
                height="2px!important"
                styles={{
                  padding: "12px 8px !important",
                  maxHeight: "7.5rem",
                  overflowY: "auto !important",
                }}
                menuPlacement="auto"
                maxMenuHeight={200}
                closeMenuOnSelect={false}
                components={animatedComponents}
                name="handlerUsers"
                id="handlerUsers"
                // isDisabled={
                //   status == "Request Information" ||
                //   status == "Supplier Maintenance" ||
                //   status == "Specialist Exception" ||                
                //   status == "Reject Invoice" ||
                //   status == "Duplicate" 
                //     ? true
                //     : false
                // }
                // value={handlerUsers}
                value={[...selectedUser, ...handlerUsers]}
                // defaultValue={[colorOptions[4], colorOptions[5]]}
                isMulti
                options={handlerUserList}
                onChange={multiSelectHandler}
                ListboxProps={{
                  style: {
                    maxHeight: "10rem",
                    border: "1px solid red",
                  },
                }}
                // onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography for="comments">Comments</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                id="outlined-basic"
                // label="Outlined"
                variant="outlined"
                type="textarea"
                name="comments"
                // id="comments"
                onBlur={handleBlur}
                helperText={
                  errors.comments && touched.comments ? errors.comments : ""
                }
                disabled={
                  status == "Request Information" ||
                  status == "Supplier Maintenance" ||
                  status == "Specialist Exception" ||                
                  status == "Reject Invoice" ||
                  status == "Duplicate" 
                    ? true
                    : false
                }
                onChange={handleChange}
                invalid={errors.comments && touched.comments ? true : false}
                value={comments}
              />
            </Grid>
         
            {
                  status == "Request Information" ||
                  status == "Supplier Maintenance" ||
                  status == "Specialist Exception" ||                
                  status == "Reject Invoice" ||
                  status == "Duplicate" ? 
                  <DialogActions sx={{ mt: 2, marginLeft: "auto" }}>
                  { !apiCall && <Button onClick={exceptionaFlagOff} disabled={false}>Close</Button>}
                     <Button type="submit" disabled={false} >Save</Button>
                   </DialogActions> : 
                    <DialogActions sx={{ mt: 2, marginLeft: "auto" }}>
                    { !apiCall && <Button onClick={exceptionaFlagOff}>Close</Button>}
                       <Button type="submit" disabled={apiCall}>Save</Button>
                     </DialogActions>
                }           
          </Grid>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}

export default ExceptionHandlerModel;
