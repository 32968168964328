import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { visuallyHidden } from "@mui/utils";
import { Divider, IconButton, Menu, Typography } from "@mui/material";
import { INVOICE_REPORT_URL } from "../comman/url";
import API from "../constants/API";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { CSVLink } from "react-csv";
import Loader from "../comman/Loader";
import moment from "moment";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { debounce, filter, initial } from "underscore";
import { FormControl, MenuItem, Select } from "@mui/material";
import MetrixInvoiceFilter from "../components/Modal/MetrixInvoiceFilter";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import PagenationPages from "../components/PagenationPages";
import { Badge } from "reactstrap";
import { statusStyleConfig } from "../constants/HelperConstant";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { TrafficRounded } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },

  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
  sortByDate: {
    display: "none",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let pattern = /^\d+$/;
  let avalue = a[orderBy];
  let bvalue = b[orderBy];
  if (orderBy == "invoiceAmount") {
    avalue = parseInt(a[orderBy]);
    bvalue = parseInt(b[orderBy]);
  }
  if (orderBy == "invoiceNumber") {
    if (pattern.test(avalue)) {
      avalue = parseInt(a[orderBy]);
      bvalue = parseInt(b[orderBy]);
    } else {
      avalue = a[orderBy];
      bvalue = b[orderBy];
    }
  }
  if (bvalue < avalue) {
    return -1;
  }
  if (bvalue > avalue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascending(a, b, orderBy);
}
function ascending(a, b, orderBy) {
  let pattern = /^\d+$/;
  let avalue = a[orderBy];
  let bvalue = b[orderBy];
  if (orderBy == "invoiceAmount") {
    avalue = parseInt(a[orderBy]);
    bvalue = parseInt(b[orderBy]);
  }
  if (orderBy == "invoiceNumber") {
    if (pattern.test(bvalue, avalue)) {
      avalue = parseInt(a[orderBy]);
      bvalue = parseInt(b[orderBy]);
    } else {
      avalue = a[orderBy];
      bvalue = b[orderBy];
    }
  }
  if (bvalue < avalue) {
    return 1;
  }
  if (bvalue > avalue) {
    return -1;
  }
  return 0;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoiceNumber",
    numeric: true,
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },
  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "invoiceCurrency",
    numeric: true,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "invCreatedDate",
  //   numeric: true,
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow style={{borderBottom: "1px solid black"}}>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={`${headCell.id != "status" && "custom-border"} `}
           
            // className={headCell.label !== "Status" ? "custom-border" : ""}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={15}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={15}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} arrow />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));

export default function MetInvoiceTable(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("invCreatedDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [totalrecords, setTotalRecords] = useState(0);
  const [totalInvoiceAmt, settotalInvoiceAmt] = useState(0);
  const [reportInvoiceModelFlag, setReportInvoiceModelFlag] = useState(false);
  const [dataparams, setdataParams] = useState({
    count: 10,
    offset: 0,
    status: "",
    fromDate: "",
    toDate: "",
    dueFromDate: "",
    dueToDate: "",
    is3PercentageRange: "",
    isDuplicate: "",
    standardDeviation: "",
    fromDate: "",
    toDate: "",
    supplierName:""
  });
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);
  const [recordsCsvData, setRecordsCsvData] = useState([]);
  const headers = [
    { label: "Invoice Number", key: "invoiceNumber" },
    { label: "Supplier Name", key: "name" },
    { label: "Amount", key: "invoiceAmount" },
    { label: "Currency", key: "invoiceCurrency" },
    { label: "Invoice Status", key: "status" },
  ];
  const getDownloadFileName = () => {
    return "InvoiceReport";
  };
  const { classes } = props;
  const styleclasses = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const openFilterHandle = () => {
    setReportInvoiceModelFlag(true);
  };
  const closeFilterHandler = () => {
    setReportInvoiceModelFlag(false);
  };
  //Api function
  const getInvoiceReports = async (paramValues) => {
    const statusApi = {
      All: "",

      "Beyond due date": "Pending",
      "Potential Duplicate Invoices": "",
      "Within 3% of Approval Limit": "Approved",
      "Standard Deviation": "",
    };
    const dateInital = {
      fromDate: "",
      toDate: "",
      dueFromDate: "",
      dueToDate: "",
    };

    const date = {
      All: {
        ...dateInital,
        fromDate: paramValues.fromDate,
        toDate: paramValues.toDate,
      },

      "Beyond due date": {
        ...dateInital,
        dueFromDate: paramValues.fromDate,
        dueToDate: paramValues.toDate,
      },
      "Potential Duplicate Invoices": {
        ...dateInital,
        fromDate: paramValues.fromDate,
        toDate: paramValues.toDate,
      },
      "Within 3% of Approval Limit": {
        ...dateInital,
        fromDate: paramValues.fromDate,
        toDate: paramValues.toDate,
      },
      "": {
        ...dateInital,
        fromDate: paramValues.fromDate,
        toDate: paramValues.toDate,
      },
    };
    
    // setExportLoading(true);
    const config = {
      method: "GET",
      url: INVOICE_REPORT_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            ...paramValues,

            status: statusApi[paramValues.status] || paramValues.status,
            ...date[paramValues.status],

            is3PercentageRange:
              paramValues.status === "Within 3% of Approval Limit"
                ? "true"
                : "",
            isDuplicate:
              paramValues.status === "Potential Duplicate Invoices"
                ? "true"
                : "",
            standardDeviation:
              paramValues.status === "Standard Deviation" ? "true" : "",
              supplierName: paramValues.supplierName?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data, count, invoiceAmount } = JSON.parse(l);
      if (status === "Success") {
        var regExp = /[a-zA-Z]/g;
        setTotalRecords(count);
        setData(
          data.map((item) => {
            return {
              ...item,
              invoiceNumber: item.invoiceNumber || "N/A",
              invoiceAmount: item.invoiceAmount || 0,
              //   invoiceSortNumber:
              //     ["", "N/A"].includes(item.invoiceNumber) ||
              //     regExp.test(item.invoiceNumber)
              //       ? 0
              //       : item.invoiceNumber,
            };
          })
        );
        settotalInvoiceAmt(invoiceAmount);
        // setLoading(false);
      }
    } catch (error) {
      

      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const delaySearch = useRef(
    debounce((dataparams) => getInvoiceReports(dataparams))
  ).current;

  useEffect(() => {
    delaySearch(dataparams);
  }, [dataparams]);

  const rowHandle = (pageCount) => {
    setdataParams((v) => ({ ...v, count: pageCount.target.value, offset: 0 }));
  };
  const nextPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previousPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };

  const filterHandler = (filterData) => {
    setdataParams((value) => ({
      ...value,
      ...filterData,
      offset: 0,
    }));
  };
  const fetchCsvData = async () => {
    const statusApi = {
      All: "",

      "Beyond due date": "Pending",
      "Potential Duplicate Invoices": "",
      "Within 3% of Approval Limit": "Approved",
      "Standard Deviation": "",
    };
    const dateInital = {
      fromDate: "",
      toDate: "",
      dueFromDate: "",
      dueToDate: "",
    };

    const date = {
      All: {
        ...dateInital,
        fromDate: dataparams.fromDate,
        toDate: dataparams.toDate,
      },

      "Beyond due date": {
        ...dateInital,
        dueFromDate: dataparams.fromDate,
        dueToDate: dataparams.toDate,
      },
      "Potential Duplicate Invoices": {
        ...dateInital,
        fromDate: dataparams.fromDate,
        toDate: dataparams.toDate,
      },
      "Within 3% of Approval Limit": {
        ...dateInital,
        fromDate: dataparams.fromDate,
        toDate: dataparams.toDate,
      },
      "": { ...dateInital },
    };
    setExportLoading(true);
    const config = {
      method: "GET",
      url: INVOICE_REPORT_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            count: totalrecords,
            //...dataparams,
            supplierName:dataparams.supplierName?.replace("'", "\\'"),
            status: statusApi[dataparams.status] || dataparams.status,
            // ...date[dataparams.status],
            fromDate:dataparams.fromDate,
            toDate:dataparams.toDate,
            is3PercentageRange:
              dataparams.status === "Within 3% of Approval Limit" ? "true" : "",
            isDuplicate:
              dataparams.status === "Potential Duplicate Invoices"
                ? "true"
                : "",
            standardDeviation:
              dataparams.status === "Standard Deviation" ? "true" : "",
          })
        ),
        flutterString: "",
      },
    };

    try {
      setLoading(true);

      const response = await API(config);
      // const { status, data, count, invoiceAmount, standardDeviationAmount } =
      //   response.data;
      let l = deCryptFun(response.data);
      const { status, data, count, invoiceAmount, standardDeviationAmount } =
        JSON.parse(l);
      if (status === "Success") {
        setRecordsCsvData(data);
        setShowExport(true);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setExportLoading(false);
      //  ref.current.link.click()
    }
  };
  useEffect(() => {
    fetchCsvData();
  }, [totalrecords, setTotalRecords]);
  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <Box sx={{ width: "100%" }}>
      <Grid item xs={12} md={12} sx={{ display: "flex", mb: 2 }}>
        <Grid item xs={12} md={5} lg={5} sx={{ display: "flex" }}>
          <Grid item xs={12} sx={{ margin: "8px 8px 16px 0px" }}>
            <Item className={styleclasses.bluebox}>
              <Typography
                align="left"
                sx={{
                  pb: 2,
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#14142A",
                }}
              >
                Total Invoices
              </Typography>
              <Typography
                align="left"
                sx={{ fontWeight: 700, fontSize: "17px", color: "#3779EC" }}
              >
                {totalrecords}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sx={{ margin: "8px 8px 16px 0px" }}>
            <Item className={styleclasses.greenbox}>
              <Typography
                align="left"
                sx={{
                  pb: 2,
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#14142A",
                }}
              >
                Invoice Amount
              </Typography>
              <Typography
                align="left"
                sx={{ fontWeight: 700, fontSize: "17px", color: "#0E9F85" }}
              >
                {totalInvoiceAmt &&
                  totalInvoiceAmt.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                USD
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          lg={7}
          sx={{ textAlign: "right", alignSelf: "center" }}
        >
          {/* <Tooltip
            title="Export as CSV"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          > */}
          <Button
            className={styleclasses.notifydownloadbtn}
            variant="contained"
            id="basic-button"
            aria-controls={openExport ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            onClick={exportClick}
            // startIcon={
            //   (exportLoading || showexport === false) && (
            //     <FacebookCircularProgress />
            //   )
            // }
            disabled={exportLoading || loading || showexport === false}
          >
            Export
          </Button>
          {/* </Tooltip> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openExport}
            onClose={exportClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
           
            <CSVLink
              key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
              data={recordsCsvData}
              headers={headers}
              filename={`${getDownloadFileName()}.csv`}
              target="_blank"
            >
              <MenuItem data-flag="csv" className={styleclasses.exportcsvmenu}>
                Export as CSV
              </MenuItem>
            </CSVLink>
          </Menu>

          <Tooltip
            title="Filter by"
            placement="top"
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
            // classes={{ arrow: classes.arrow }}
            arrow
          >
            <IconButton aria-label="delete" onClick={openFilterHandle}>
              <FilterListRoundedIcon className={styleclasses.filtercolor} />
            </IconButton>
          </Tooltip>

          {/* <LightTooltip
            title="Filter by"
            placement="top"
            className={styleclasses.filterby}
            classes={{ arrow: styleclasses.arrow }}
          >
            <IconButton onClick={openFilterHandle}>
              <FilterListRoundedIcon className={styleclasses.filtercolor} />
            </IconButton>
          </LightTooltip> */}
          {reportInvoiceModelFlag && (
            <MetrixInvoiceFilter
              openModal={reportInvoiceModelFlag}
              closeModal={closeFilterHandler}
              filterHandler={filterHandler}
              dataparams={dataparams}
            />
          )}
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%", mb: 2, borderRadius: "12px" }}>
        <EnhancedTableToolbar numSelected={selected.length} />

        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!loading && Data && Data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              )}

              <>
                {!loading &&
                  stableSort(Data, getComparator(order, orderBy)).map(
                    (dataList, index) => {
                      const {
                        invoiceId,
                        invoiceNumber,
                        name,
                        invoiceAmount,
                        invoiceCurrency,
                        status,
                        invCreatedDate,
                      } = dataList;
                      const isItemSelected = isSelected(Data.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, invoiceId)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {invoiceNumber}
                          </TableCell>
                          <TableCell align="center">{name}</TableCell>
                          <TableCell align="center">
                            {invoiceAmount ? invoiceAmount
                          // Number(invoiceAmount.match(/^\d+(?:\.\d{0,2})?/))
                            : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {invoiceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            <Badge color={statusStyleConfig[status]}>
                              {status}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </>

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <PagenationPages
        dataparams={dataparams}
        rowHandle={rowHandle}
        totalRecord={totalrecords}
        previorsPage={previousPage}
        nextPage={nextPage}
      />
    </Box>
  );
}