
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputBase,
  NativeSelect,
  Grid,
  TextField,
  Box,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { makeStyles } from "@mui/styles";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  getUserId,
  stableSort,
} from "../../comman/function";
import { useFormik } from "formik";
import { PASSWORD_REGEX, NUMBER_REGEX } from "../../comman/constants";
import { CREATE_CUSTOMER, UPDATE_USER,ADD_SUB_SUPPLIER } from "../../comman/url";
import API from "../../constants/API";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import Swal from "sweetalert2";
import { CustomMessage } from "../../comman/helperfunction";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 500,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};



var teamInit = {
  firstName: "",
  lastName: "",
  password: "",
  countryCode: "",
  phoneNumber: "",
  email: "",
  confirmpassword: "",
  approvalAmountTo:"",
  planId: 1,
  userType:"Supplier" ,
  userId:"",

};
let touchedInitial = {
  firstName: false,
  userId: false,
  profileLogo: false,
  lastName: false,
  approvalAmountFrom: false,
  approvalAmountTo: false,
  password: false,
  countryCode: false,
  phoneNumber: false,
  createdBy: false,
  email: false,
  confirmpassword: false,
  address: false,
  userRole: false,
  exceptionRoleIdList: false,
};


export default function AddSubSupplier({
  open,
  addToggleClose,
  initialData,
  updateflag = false,
  supplierData,
  successCallBack
 
}) {
  const styleclasses = useStyles();
  
  const [passWordFlag, setpassWordFlag] = useState(false);
  const passwordToggle = () => setpassWordFlag((v) => !v);
  const [confirmpassWordFlag, setconfirmpassWordFlag] = useState(false);
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserRole, setUpdateUserRole] = useState("");
  const [code, setCode] = useState("");
  const [snum, setSnum] = useState();
  const confirmpasswordToggle = () => setconfirmpassWordFlag((v) => !v);
 
  const closeModal = () => {
    setValues({ ...teamInit });
    setpassWordFlag(false);
    setconfirmpassWordFlag(false)
    setTouched(touchedInitial);
    addToggleClose();
  };
  const validationSchema = () =>
    Yup.object().shape(
      {
        firstName: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Required"),
        lastName: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
       

        password: Yup.string().when([], {
          is: () => !updateflag,
          then: Yup.string()
            .required("Required")
            .matches(
              PASSWORD_REGEX,
              "Invalid password. New password must be 8 characters minimum and contain at least one upper/lowercase letter and one special character."
            ),
            // .min(
            //   "6",
            //   "Invalid Password !,Password must be longer than 6 characters"
            // ),
          otherwise: Yup.string().notRequired(),
        }),
        confirmpassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .when([], {
            is: () => !updateflag,
            then: Yup.string().required("Required"),
            otherwise: Yup.string().notRequired(),
          }),
        }
    );
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    setTouched,
  } = useFormik({
    initialValues: {
      ...teamInit,
   ...initialData
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => SuccessRoute(values),
  });

  useEffect(() => {
    setValues((v) => ({ ...v, ...initialData }));
  }, [initialData]);

  const phoneNumberChange = (value) => {
    setValues({ ...values, phoneNumber: value });
    if (value) {
      const l = formatPhoneNumberIntl(value);
      const coden = l.split(" ")[0];
      setCode(coden);
      const number = value.split(code)[1];
      setSnum(number);
    }
  };



  let SuccessRoute = async (values) => {
    setloading(true)
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmpassword,
      userId,
      companyName,
      userType,
      planId,
      approvalAmountTo,
    } = values;
    let l, coden, number;
    if (phoneNumber) {
      l = formatPhoneNumberIntl(phoneNumber);
      coden = l.split(" ")[0];
      setCode(coden);
      number = phoneNumber.split(coden)[1];
    }

    if (code && snum) {
      if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
        const config = {
          
          method:  updateflag ? "PUT" : "POST",
          url: updateflag ? UPDATE_USER : ADD_SUB_SUPPLIER,
          headers: authHeader(),
          data: {
            webString: enCryptFun(
              JSON.stringify({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email,
               
                phoneNumber: number,
                password,
                confirmpassword,
                countryCode: code,
                userRole: "Sub Supplier",
                approvalAmountFrom:0,
                approvalAmountTo:0,
                exceptionRoleIdList:"",
                createdBy:  getUserId(),
                userId
              })
            ),
            flutterString: "",
          },
        };
        try {
         
          const response = await API(config);
          let l = deCryptFun(response.data);

          const { status, message } = JSON.parse(l);
          if (status === "Success") {
            CustomMessage(
              updateflag?
              "Supplier Created":"Supplier Updated",
              "success",
              enqueueSnackbar
            ); 
            setloading(false)
            setValues({ ...teamInit });
            setTouched(touchedInitial);
            setpassWordFlag(false);
            setconfirmpassWordFlag(false)
            successCallBack()
          } else {
            CustomMessage(
              message,
              "error",
              enqueueSnackbar
            );
          }
        } catch (error) {
        
          if (error.response) {
            let { data } = error.response;
            let p = deCryptFun(data);
            let v = JSON.parse(p);
            
            CustomMessage(v.message, "error", enqueueSnackbar);
          }
          
          // Swal.fire("Error", data.message);
        } finally {
          
        }
      } else {
        CustomMessage(
          "Please fill valid Phone number",
          "error",
          enqueueSnackbar
        );
      }
    } else {
      const config = {
        method:  updateflag ? "PUT" : "POST",
        url: updateflag ? UPDATE_USER : ADD_SUB_SUPPLIER,
        headers: authHeader(),
        data: {
          webString: enCryptFun(
            JSON.stringify({
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              email,
              phoneNumber: code ? number : "",
              password,
              confirmpassword,
              countryCode: code ? code : "",
              userRole: "Sub Supplier",
              approvalAmountFrom:0,
              approvalAmountTo:0,
              exceptionRoleIdList:"",
             userId,
              createdBy:  getUserId(),
            })
          ),
          flutterString: "",
        },
      };
      try {
        const response = await API(config);
        // const { status, message } = response.data;
        let l = deCryptFun(response.data);
        const { status, message } = JSON.parse(l);
        if (status === "Success") {
          setloading(false)
          CustomMessage(
            "Supplier Created",
            "success",
            enqueueSnackbar
          ); //call toster
          setValues({ ...teamInit });
          setTouched(touchedInitial);
          setpassWordFlag(false);
          setconfirmpassWordFlag(false)
          successCallBack()
        } else {
          Swal.fire("Error", message);
        }
      } catch (error) {
        if (error.response) {
          let { data } = error.response;

          let p = deCryptFun(data);
          let v = JSON.parse(p);
          Swal.fire("Error", v.message);
        }
      } finally {
     
      }
    }
  };
  const handleApprovalHandle = (e) => {
    const approvalRegex = /^[0-9,]+$/;
    if (approvalRegex.test(e.target.value) || !e.target.value) {
      handleChange(e);
    }
  };
  

  return (
    <>
       <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box component={"form"} onSubmit={handleSubmit}>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={closeModal}
              className={styleclasses.BootstrapDialog}
              sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
            >
              
               {updateflag ? `Update ${values.userRole}` : "Add team member"} 
            </BootstrapDialogTitle>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                First Name
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.firstName}
                placeholder="Enter First Name"
                onBlur={handleBlur}
                name="firstName"
                onChange={handleChange}
                error={touched.firstName && errors.firstName}
                // helperText={touched.firstName && errors.firstName}
              />
              <FormHelperText error={true}>
                {touched.firstName && errors.firstName}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Last Name
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.lastName}
                onBlur={handleBlur}
                // helperText={touched.lastName && errors.lastName}
                error={touched.lastName && errors.lastName}
                placeholder="Enter Last Name"
                name="lastName"
                onChange={handleChange}
              />
              <FormHelperText error={true}>
                {touched.lastName && errors.lastName}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Email
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.email}
                disabled={updateflag}
                placeholder="Enter Email"
                error={touched.email && errors.email}
                onBlur={handleBlur}
                // helperText={touched.email && errors.email}
                name="email"
                onChange={handleChange}
              />
              <FormHelperText error={true}>
                {touched.email && errors.email}
              </FormHelperText>
            </Grid>{" "}
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Phone Number
              </Typography>
              <PhoneInput
                name="phoneNumber"
                placeholder="Enter Phone Number"
                value={values.phoneNumber}
                onBlur={handleBlur}
                defaultCountry="US"
                onChange={phoneNumberChange}
                international="false"
                error={
                  values.phoneNumber
                    ? isValidPhoneNumber(values.phoneNumber)
                      ? "correct"
                      : "Invalid phone number"
                    : ""
                }
              />
              <FormHelperText error={true}>
                {touched.phoneNumber && errors.phoneNumber
                  ? errors.phoneNumber
                  : ""}
              </FormHelperText>
            </Grid>
           {/* <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography 
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Approval Threshold
                </Typography>
                <OutlinedInput
                  fullWidth
                  type="text"
                  error={touched.approvalAmountTo && errors.approvalAmountTo}
                  // helperText={touched.approvalAmountTo && errors.approvalAmountTo}
                  id="custom-css-outlined-input"
                  value={values.approvalAmountTo}
                  onBlur={handleBlur}
                  placeholder="Maximum Range ($)"
                  name="approvalAmountTo"
                  // onChange={autoApprovalHandle}
                  onChange={(e) => handleApprovalHandle(e)}
                />
                <FormHelperText error={true}>
                  {touched.approvalAmountTo && errors.approvalAmountTo}
                </FormHelperText>
              </Grid> */}
              {/* <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Company Name
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.companyName}
                disabled={updateflag}
                placeholder="Enter Company Name"
                error={touched.companyName && errors.companyName}
                onBlur={handleBlur}
                name="companyName"
                onChange={handleChange}
              />
              <FormHelperText error={true}>
                {touched.companyName && errors.companyName}
              </FormHelperText>
            </Grid>{" "} */}
            
            
          
            {!updateflag && (
              <>
                {" "}
                <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600, color: "#4E4B66" }}
                  >
                    Password
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={passWordFlag ? "text" : "password"}
                    error={errors.password}
                    onBlur={handleBlur}
                    placeholder="Enter Password"
                    // helperText={errors.password}
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={passwordToggle}
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {!passWordFlag ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  <FormHelperText id="component-error-text" error={true}>
                    {touched.password && errors.password}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600, color: "#4E4B66" }}
                  >
                    Confirm Password
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={confirmpassWordFlag ? "text" : "password"}
                    value={values.confirmpassword}
                    error={touched.confirmpassword && errors.confirmpassword}
                    name="confirmpassword"
                    onBlur={handleBlur}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={confirmpasswordToggle}
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {confirmpassWordFlag ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  <FormHelperText id="component-error-text" error={true}>
                    {touched.confirmpassword && errors.confirmpassword}
                  </FormHelperText>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button
                onClick={closeModal}
                className={styleclasses.canceldialog}
              >
                Cancel
              </Button>
              <Button
                type="submit "
                onSubmit={handleSubmit}
                disabled={loading}
                className={styleclasses.submitdialog}
              >
                {/* {updateflag ? `Save` : "Add"} */}
                Save
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  )
}


