export const SIGNIN_ROUTE = "/signin";
export const FORGOTPASSWORD_ROUTE = "/forgotpassword";
export const SIDEBAR_ROUTE = "/sidebar";
export const DASHBOARD_ROUTE = "/dashboard";
export const INVOICE_ROUTE = "/invoice";
export const INVOICEPREVIEW_ROUTE = "/invoice-details";
export const SUPPLIER_SITE_ROUTE = "/supplier-detials";
export const INVOICE_PREVIEW_ROUTE = "/invoice-preview";
export const MYTEAM_ROUTE = "/myteam";
export const SUPPLIERMANAGEMENT_ROUTE = "/supplier";
export const MYCLIENTS_ROUTE = "/myclients";
export const SETTINGS_ROUTE = "/settings";
export const METRICSINVOICE_ROUTE = "/metrics";
export const PROFILE_ROUTE = "/profile";
export const AUTHENTIFICATION_ROUTE = "/authentification";
export const SIGNUP_ROUTE = "/signup";
export const UPLOAD_INVOICE_ROUTE = "/upload-invoice";
export const UPDATE_PASSWORD_ROUTE = "/update-password";
export const REGISTERED_ROUTE = "/registered";
export const VERIFY_EMAIL_ROUTE = "/verifyemail";
export const ADD_SUPPLIER = "/suppliers";
export const NOTIFICATION_ROUTE = "/notification";
