import React from "react";
import Dynamsoft from "dwt";
import { Row } from "reactstrap";
import { makeStyles } from "@mui/styles";
import { Button, ButtonBase } from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import Swal from "sweetalert2";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};



export default class DWT extends React.Component {
  // const styleclasses = useStyles();
  
  DWObject = null;
  containerId = "dwtcontrolContainer";

  state = {
    sources: [],
    selectedSource: null,
    blobUrl: "",
    selectedFile:""
  };

  componentDidMount() {
  
    Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", () => {
      this.Dynamsoft_OnReady();
    });
    Dynamsoft.DWT.handshakeCode = "100583444-100583458";
    Dynamsoft.DWT.organizationID = "100583444";
    Dynamsoft.DWT.UseLocalService = 1;
    Dynamsoft.DWT.ResourcesPath = "dwt-resources";
    Dynamsoft.DWT.Containers = [
      {
        WebTwainId: "dwtObject",
        ContainerId: this.containerId,
        Width: "300px",
        Height: "400px",
        closeButton:true
      },
    ];
    Dynamsoft.DWT.Load();
   
  }
  Dynamsoft_OnReady() {
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
    const sources = this.DWObject.GetSourceNames();
    this.setState({ sources, selectedSource: sources.length ? 0 : null });
  
  }
  
  acquireImage = () => {
    const handleBlobChange = this.props.handleBlobChange;
    const closeDialog=this.props.closeModal
    this.DWObject.AcquireImage(
      (e) => {
        this.DWObject.CloseSource();
        this.DWObject.ConvertToBlob(
          //[...Array(1 + this.DWObject.ImageLayoutPageNumber).keys()],
          [0, 1, 2],        
          Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
          function (result, indices, type) {
            handleBlobChange(result);
            this.setState({selectedFile:result})
            
            
          },
          function (errorCode, errorString) {
            if(errorString=="The indices are out of range."){
              // closeDialog()
              Swal.fire({
              
                title: 'Scan Again!!',
                // background:"red",
                showConfirmButton: false,
                timer: 2000
              })
            }
            
           
          }
        );

        
      },
      (e) => {
        this.DWObject.CloseSource();
        
      }
    );
  };
  
  render() {
  
    return (
      <BootstrapDialog
       onClose={this.props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.openModal}
        // fullWidth
        // maxWidth="md"
        // PaperProps={{
        //   sx: {
        //     "&::-webkit-scrollbar": {
        //       width: "0.5em",
        //     },
        //     "&::-webkit-scrollbar-track": {
        //       boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        //       webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        //     },
        //     "&::-webkit-scrollbar-thumb": {
        //       backgroundColor: "#A0A3BD",
        //       outline: "1px solid #A0A3BD",
        //       borderRadius: "50px",
        //     },
        //   },
        // }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={this.props.closeModal}>
          Scan Invoice
        </BootstrapDialogTitle>
       <DialogContent>
          <div id={this.containerId} className="scanimagecor">
            {" "}
          </div>
          
          </DialogContent>
          <DialogActions>
          
          <Button
            color="primary"
            className="scanbtn"
            onClick={() => this.acquireImage()}
          >
            {this.state.blobUrl ? "Re-scan" : "Scan"}
          </Button>
          <Button
            color="primary"
            className="scanbtn"
            onClick={() => this.props.submitInvoice()}
          >
           Submit
          </Button>
          
          </DialogActions>
          {/* <DialogActions>
          <Button onClick={this.props.closeModal} autoFocus>
          close
        </Button>
          </DialogActions> */}
         
      
      </BootstrapDialog> 
       
    );
  }
}
