import * as React from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  NativeSelect,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

let filterInitial = {
  selectedStatus: "",
  name: "",
  selectedDate: "",
  selectedDate1: "",
  matchAddress: "",
};

function MetricInvoiceAddressFilter(props) {
  const styleclasses = useStyles();
  const {
    open,
    handleClose,
    nameData,

    handleFilterSubmit,
    onResetFilter,
    dataParams,
  } = props;
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...filterInitial,
      ...dataParams,
    },
    onSubmit: (values) => {
      if (values.selectedDate != "" && values.selectedDate != "Invalid date") {
        if (
          values.selectedDate1 === "" ||
          values.selectedDate1 == "Invalid date"
        ) {
          setFieldError("selectedDate1", "End date is required");
          return null;
        }
      }
      if (
        values.selectedDate1 != "" &&
        values.selectedDate1 != "Invalid date"
      ) {
        if (
          values.selectedDate === "" ||
          values.selectedDate === "Invalid date"
        ) {
          setFieldError("selectedDate", "Start date is required");
          return null;
        }
      }
      handleFilterSubmit(values);
    },
  });
  const resetField = () => {
    resetForm({});
    handleFilterSubmit(filterInitial);
    onResetFilter();
  };
  const handleFromDate = (e) => {
    setValues((val) => ({
      ...val,
      selectedDate: moment(e).format("YYYY-MM-DD"),
    }));
  };

  const handleToDate = (e) => {
    setValues((val) => ({
      ...val,
      selectedDate1: moment(e).format("YYYY-MM-DD"),
    }));
  };
  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className={styleclasses.BootstrapDialog}
        sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
      >
        Filters
      </BootstrapDialogTitle>
      <Box component={"form"} onSubmit={handleSubmit}>
        <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Supplier
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <NativeSelect
                id="demo-customized-select-native"
                value={values.name}
                onChange={handleChange}
                name="name"
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="">
                  Show all
                </option>
                <option value="jeyabaskaranm@apptomate.co">
                  Jeya Baskaran
                </option>
                {nameData &&
                  nameData.length !== 0 &&
                  nameData.map((i) => {
                    const { firstName, lastName, email } = i;
                    return (
                      <option value={email} key={email}>
                        {firstName} {lastName}
                      </option>
                    );
                  })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Matching Address
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <NativeSelect
                id="demo-customized-select-native"
                value={values.matchAddress}
                onChange={handleChange}
                name="matchAddress"
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="">
                  Show all
                </option>
                <option value="true">Matched</option>
                <option value="false">Not Matched</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              From
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack sx={{ display: "flex", flexDirection: "row" }} spacing={3}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  name="selectedDate"
                  value={
                    values.selectedDate ? new Date(values.selectedDate) : null
                  }
                  maxDate={
                    values.selectedDate1
                      ? new Date(values.selectedDate1)
                      : new Date()
                  }
                  onChange={handleFromDate}
                  allowSameDateSelection
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "MM/DD/YYYY",
                      }}
                      sx={{ width: "100% !important" }}
                      helperText={
                        errors.selectedDate ? errors.selectedDate : ""
                      }
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              To
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack sx={{ display: "flex", flexDirection: "row" }} spacing={3}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  name="selectedDate1"
                  value={
                    values.selectedDate1 ? new Date(values.selectedDate1) : null
                  }
                  allowSameDateSelection
                  onChange={handleToDate}
                  minDate={new Date(values.selectedDate)}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100% !important" }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "MM/DD/YYYY",
                      }}
                      helperText={
                        errors.selectedDate1 ? errors.selectedDate1 : ""
                      }
                      error={errors.selectedDate1}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button
              type="submit"
              className={styleclasses.submitdialog}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogActions>
      </Box>
    </BootstrapDialog>
  );
}

export default MetricInvoiceAddressFilter;
