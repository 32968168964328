import React, { useState ,useEffect } from "react";
import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputBase,
  NativeSelect,
  Grid,
  TextField,
  Box,
  Select,
  MenuItem,
} from "@mui/material";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";
import moment from "moment";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
} from "../../comman/function";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  exportgrid: {
    top: "150px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

const ExportInvoiceFilter = ({
  open,
  close,
  cronJobChoise,
  handleCronJobChange,
  cronScheduleType,
  CroneSchedulle,
  editFlag,
  timeChanged,
  updateCron,
  saveCronDetails,
  everyDayTime
}) => {
  const styleclasses = useStyles();
  const closetoggle = () => {
    close();
  };
  const chkfuncr = (e) => {
    if (editFlag === true) {
      updateCron(e);
      close();
    } else {
      saveCronDetails(e);
      close();
    }
  };

  const [isValid, setIsValid] = useState('false')
  useEffect(()=>{
    setIsValid(moment(everyDayTime).format("HH:mm") == "Invalid date" ? true: false)
  },[everyDayTime])

  return (
    <>
      {/* <Typography>Export Data Schedule</Typography>
      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
        <Select
          sx={{ m: 1, minWidth: 180 }}
          displayEmpty
          value={cronJobChoise}
          onChange={handleCronJobChange}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="">Select</MenuItem>
          {cronScheduleType.map((el, i) => {
            return (
              <MenuItem key={i} value={el.value}>
                {el.label}
              </MenuItem>
            );
          })}
        </Select>

        <CroneSchedulle cronJobChoise={cronJobChoise} />
        <Button
          disabled={timeChanged === false}
          onClick={(e) =>
            editFlag === true ? updateCron(e) : saveCronDetails(e)
          }
        >
          {editFlag ? "Update" : "Save"}
        </Button>
        <Button onClick={getInstantReport}>Generate now</Button>
      </Grid> */}
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closetoggle}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Schedule
        </BootstrapDialogTitle>
        <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Type
            </Typography>
            <Select
              //sx={{ m: 1, minWidth: 180 }}
              fullWidth
              displayEmpty
              value={cronJobChoise}
              onChange={handleCronJobChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value=""> Select</MenuItem>
              {cronScheduleType.map((el, i) => {
                return (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {cronJobChoise ? (
            <CroneSchedulle cronJobChoise={cronJobChoise} />
          ) : (
            ""
          )}
          {isValid ? <span style={{color:"red"}}>Time is required</span>:""}
        </DialogContent>

        <Box component={"form"}>
          <DialogActions>
            <DialogActions>
              <Button
                className={styleclasses.canceldialog}
                onClick={closetoggle}
              >
                Reset
              </Button>
              <Button
                disabled={timeChanged === false || cronJobChoise === ""|| isValid===true}
                onClick={
                  chkfuncr
                  // (e) =>
                  // editFlag === true ? updateCron(e) : saveCronDetails(e)
                }
                className={styleclasses.submitdialog}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default ExportInvoiceFilter;
