import React, { useState, useEffect, useRef } from "react";
import { debounce } from "underscore";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Loader from "../comman/Loader";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  DELETE_TEAM_MEMBER,
  GET_ASSIGNED_TEAM_LIST,
  GET_SUPPLIER_LIST_URL,
  GET_USERS,
  TEAM_MEMBER_ASSIGN,
} from "../comman/url";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import { produce } from "immer";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getTeamID,
} from "../comman/function";
import API from "../constants/API";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "./NoRecordFound";
import PagenationPages from "./PagenationPages";
import FilterTeam from "./Modal/FilterTeam";
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  deactiverow: {
    background: "#f0f0f08f !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    color: "#14142A !important",
    padding: "4px !important",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  cancelButtonColor: {
    margin: "18px 0px 0px 8px !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#F14A4A !important",
      color: "white !important",
    },
  },
  notifydownloadbtnoutline: {
    marginTop: "0px !important",
    fontSize: "14px !important",
    margin: "18px 0px 0px 8px !important",
    borderColor: "#EC7C37 !important",
    // backgroundColor: "#EC7C37 !important",
    color: "#EC7C37 !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
    overflow: "hidden",
  },

  notifydownloadbtn: {
    marginTop: "0px !important",
    margin: "18px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    // "&:hover": {
    //   backgroundColor: "#D0580F !important",
    //   color: "white !important",
    // },
  },
  exportgrid: {
    top: "105px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let a_value = a[orderBy];
  let b_value = b[orderBy];
  if (
    orderBy === "firstName" ||
    orderBy === "lastName" ||
    orderBy === "email"
  ) {
    a_value = a_value?.toLowerCase();
    b_value = b_value?.toLowerCase();
  }
  if (b_value < a_value) {
    return -1;
  }
  if (b_value > a_value) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "firstName",
    numeric: true,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },

  {
    id: "approvalAmountTo",
    numeric: true,
    disablePadding: false,
    label: "Approval Range",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow style={{borderBottom: "1px solid black"}}>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={headCell.id !== "action" ? "custom-border" : ""}
            // className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.id === "action"}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
let filterInitial = {
  count: 10,
  offset: 0,
  email: "",
  firstName: "",
  lastName: "",
};

let assignFilter = {
  count: 10,
  offset: 0,
  supplierName: "",
};
export default function Approvedassignment(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [reqFilter, setReqFilter] = useState(false);
  const [reqFilternew, setReqFilternew] = useState(false);
  const [filter, setFilter] = useState(filterInitial);
  const [teamApprovalFilter, setTeamApprovalFilter] = useState(filterInitial);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const styleclasses = useStyles();
  const [valuesChanges, setValuesChanges] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [apiAddignedTeam, setapiAddignedTeam] = useState({});
  const [apiinitialAddignedTeam, setapiinitialAddignedTeam] = useState({});

  const [totalRecord, setTotalRecords] = useState(0);
  const [teamFilter, setteamFilter] = useState(filterInitial);
  const [teamData, setTeamData] = useState([]);
  const [addMemberFlag, setaddMemberFlag] = useState(true);
  const [Data, setData] = useState([]);

  const addMemberToogle = () => setaddMemberFlag((va) => !va);
  //const [supplierName, setSupplierName] = useState("");
  const [assignListFilter, setAssignListFilter] = useState(assignFilter);
  const [totalAssignRecord, setTotalAssignRecord] = useState(0);
  const [assignedTeamList, setAssignedTeamList] = useState([]);

  const [dense, setDense] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [loading, setLoading] = useState(false);

  //filter
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [dataparams, setdataParams] = useState(filterInitial);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const delayAssignSearch = useRef(
    debounce((dataparams) => getAssignedTeamList(dataparams))
  ).current;
  const delaySearch = useRef(
    debounce((dataparams) => GetMember(dataparams))
  ).current;
  const getSupplierList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            supplierId: getEmail(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);
      setSupplierList(data);
      // setSupplierList([]);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  useEffect(() => {
    getSupplierList();
  }, []);

  // useEffect(() => {
  //   if (supplierName) getAssignedTeamList(supplierName);
  // }, [supplierName]);

  const supplierHandle = (e) => {
    //setSupplierName(e.target.value);
    setAssignListFilter((val) => ({
      ...val,
      offset: 0,
      supplierName: e.target.value,
    }));
    setaddMemberFlag(true);
  };

  const getAssignedTeamList = async (filterVal) => {
    // setTeamMemberList([]);
    if (filterVal.supplierName) {
      const config = {
        method: "GET",
        url: GET_ASSIGNED_TEAM_LIST,
        headers: authHeader(),
        params: {
          webString: enCryptFun(
            JSON.stringify({
              teamId: getTeamID(),
              ...filterVal,
              supplierName: filterVal.supplierName,
            })
          ),
          flutterString: "",
        },
      };
      try {
        const response = await API(config);
        // const { data } = response.data;
        let l = deCryptFun(response.data);
        const { status, data, totalCount } = JSON.parse(l);
        // setTotalAssignRecord(totalCount);
        // setSupplierList(data);
        if (status == "Success") {
          setdataParams((val) => ({
            ...val,
            email: "",
            firstName: "",
            lastName: "",
            offset: 0,
          }));
          setTotalAssignRecord(totalCount);
          setAssignedTeamList(data);
          let teammemberobject = {};
          data.forEach((el) => {
            teammemberobject[el.teamUserId] = {
              assignSupplierId: el.assignSupplierId,
              teamUserId: el.teamUserId,
              isDeleted: el.isDeleted,
              supplierName: assignListFilter.supplierName,
            };
          });

          setapiinitialAddignedTeam(teammemberobject);
          setapiAddignedTeam(teammemberobject);
        }
      } catch (error) {
        console.error(error);
        if (error.response) {
          let { data } = error.response;

          let p = deCryptFun(data);
          let v = JSON.parse(p);
          //Swal.fire(getAlert("Error", v.message));
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        }
      }
    }
  };
  const closeReqFilterSupplier = () => {
    setReqFilter(false);
  };
  const closeReqFilterSuppliernew = () => {
    setReqFilternew(false);
  };
  const onResetFilterSupplier = () => {
    setReqFilter(false);
  };
  let GetTeamMember = async () => {
    const config = {
      method: "GET",
      url: GET_USERS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            //userRole: "Team Member",
            count: totalRecord,
            offset: 0,
          })
        ),
        flutterString: "",
      },
      // params:{
      //    teamId: getTeamID(),
      //       userRole: "Team Member",
      //       ...filterparams,
      // }
    };
    try {
      // setLoading(true)
      const response = await API(config);

      let l = deCryptFun(response.data);

      const { status, data, count } = JSON.parse(l);
      // const { status, data, count } = response.data;
      if (status === "Success") {
        //setData(data);
        // setTotalRecords(count);
        setTeamData(data);
        // setLoading(false)
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };

  useEffect(() => {
    GetTeamMember();
  }, [totalRecord]);

  useEffect(() => {
    delaySearch(dataparams);
  }, [dataparams]);
  useEffect(() => {
    delayAssignSearch(assignListFilter);
  }, [assignListFilter]);

  let GetMember = async (filterparams) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_USERS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            //userRole: "Team Member",
            ...filterparams,
            email: filterparams.email?.replace("'", "\\'"),
            firstName: filterparams.firstName?.replace("'", "\\'"),
            lastName: filterparams.lastName?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      // setLoading(true)
      const response = await API(config);

      let l = deCryptFun(response.data);

      const { status, data, count } = JSON.parse(l);
      // const { status, data, count } = response.data;
      if (status === "Success") {
        setData(data);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetMember(teamFilter);
  }, [teamFilter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const nextPage = () => {
    setteamFilter((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsPage = () => {
    setteamFilter((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const rowHandle = (ve) => {
    setteamFilter((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  const nextAssignPage = () => {
    setAssignListFilter((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsAssignPage = () => {
    setAssignListFilter((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const rowAssignHandle = (ve) => {
    setAssignListFilter((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  const assignTeamMember = async () => {
    
    let teamAdd = [];
    Object.values(apiAddignedTeam).forEach((val) => {
      if (val.assignSupplierId == 0) {
        if (val.isDeleted == 0) {
          teamAdd.push(val);
        }
      } else if (val.assignSupplierId != 0) {
        teamAdd.push(val);
      }
    });
    const config = {
      method: "POST",
      url: TEAM_MEMBER_ASSIGN,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            teamList: teamAdd,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);
      // Swal.fire(getAlertToast("Success", "Team member added successfully"));
      CustomMessage(
        "Changes saved",
        "success",
        enqueueSnackbar
      ); //call toster
      setValuesChanges(false);
      getAssignedTeamList(assignListFilter);
      loading(true);
      setaddMemberFlag(true);
      // setSupplierList(data);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
      }
    } finally {
      cancelMember();
    }
  };
  const handleAssignSupplier = (e) => {
    setValuesChanges(true);
    const { checked } = e.target;
    const { userid } = e.currentTarget.dataset;
    let cloneApiteam = { ...apiAddignedTeam };
    cloneApiteam[userid] = {
      supplierName: assignListFilter.supplierName,
      ...(cloneApiteam[userid] || {}),
      assignSupplierId: cloneApiteam[userid]?.assignSupplierId || 0,
      teamUserId: userid,
      isDeleted: checked ? 0 : 1,
    };
    setapiAddignedTeam(cloneApiteam);
  };
  const deleteTeamMemberfromSupplier = async (teamid) => {
    let cloneInvoiceData = [...assignedTeamList];
    const updatedInvoiceArray = produce(cloneInvoiceData, (draft) => {
      const index = draft.findIndex((todo) => todo.teamUserId == teamid);
      if (index !== -1) draft[index].isDeleted = 1;
    });

    const config = {
      method: "POST",
      url: TEAM_MEMBER_ASSIGN,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            teamList: updatedInvoiceArray,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);
      // Swal.fire(
      //   getAlertToast(
      //     "Success",
      //     "Team member removed from supplier successfully"
      //   )
      // );
      CustomMessage(
        "Team member removed from supplier successfully",
        "success",
        enqueueSnackbar
      ); //call toster

      getAssignedTeamList(assignListFilter);
      // setSupplierList(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const teamMemberhandledelete = (e) => {
    //const teamUserId
    const { userid } = e.currentTarget.dataset;
    //const isactive = e.currentTarget.dataset.isactive;
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#F14A4A",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteTeamMemberfromSupplier(userid);
      }
    });
  };
  const cancelMember = () => {
    setapiAddignedTeam(apiinitialAddignedTeam);
    addMemberToogle();
    setValuesChanges(false);
    setdataParams((val) => ({
      ...val,
      email: "",
      firstName: "",
      lastName: "",
    }));
  };

  const onFilterSubmit = (values) => {
    const { firstName, lastName, email } = values;
    setteamFilter((v) => ({
      ...v,
      offset: 0,
      firstName: firstName,
      lastName: lastName,
      email: email,
    }));
  };
  const TeamfilterHandler = (filterData) => {
    setdataParams((value) => ({
      ...value,
      ...filterData,
      offset: 0,
    }));
  };
  return (
    <>
      <Grid container sx={{ zIndex: "0" }} spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            textAlign: "right",
            ml: "auto",
            display: "flex",
            alignItems: "center",
          }}
          className={styleclasses.exportgrid}
        >
          <FormControl
            sx={{
              // m: 1,
              width: 300,
            }}
          >
            <InputLabel id="demo-multiple-name-label">Supplier Name</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={assignListFilter.supplierName}
              placeholder="Select Supplier name"
              label="Supplier Name"
              onChange={supplierHandle}
              sx={{ textAlign: "left" }}
            >
              {supplierList.length === 0 && (
                <MenuItem value="">No Record Found</MenuItem>
              )}
              {supplierList.length !== 0 && (
                <MenuItem value="">Select supplier name</MenuItem>
              )}
              {supplierList.map((val, index) => {
                const { supplierName } = val;
                return <MenuItem key={index} value={supplierName}>{supplierName}</MenuItem>;
              })}
            </Select>
          </FormControl>
          {assignListFilter.supplierName && (
            <>
              {addMemberFlag ? (
                <Button
                  onClick={addMemberToogle}
                  className={styleclasses.notifydownloadbtn}
                  variant="contained"
                >
                  Add User
                </Button>
              ) : (
                <>
                  {" "}
                  {/* <Button
                  onClick={addMemberToogle}
                  className={styleclasses.notifydownloadbtn}
                  variant="contained"
                >
                  View member
                </Button> */}
                  <Button
                    variant="contained"
                    disabled={!valuesChanges}
                    className={styleclasses.notifydownloadbtn}
                    onClick={assignTeamMember}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    className={styleclasses.notifydownloadbtnoutline}
                    onClick={cancelMember}
                    // color="error"
                  >
                    Cancel
                  </Button>
                  <Tooltip
                    title="Filter by"
                    placement="top"
                    arrow
                    classes={{
                      arrow: styleclasses.arrow,
                      tooltip: styleclasses.tooltip,
                    }}
                  >
                    <IconButton onClick={filterToggle}>
                      <FilterListRoundedIcon
                        className={styleclasses.filtercolor}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2, borderRadius: "16px" }}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            {addMemberFlag ? (
              <TableContainer className={styleclasses.tableContainerbg}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {assignedTeamList.length === 0 ? (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          <NoRecordFound />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {stableSort(
                          assignedTeamList,
                          getComparator(order, orderBy)
                        )
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((datalist, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const {
                              assignSupplierId,
                              teamUserId,
                              isDeleted,
                              isActive,
                            } = datalist;
                            // let teamMemberData = teamData.find((el) => {
                            //   return el.userId === teamUserId;
                            // });
                            
                            let teamMemberData = datalist;
                            if (teamMemberData)
                              return (
                                <TableRow
                                  hover
                                  // onClick={(event) => handleClick(event, name)}
                                  // aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={index}
                                  className={
                                    isActive == 0
                                      ? styleclasses.deactiverow
                                      : ""
                                  }
                                  // selected={isItemSelected}
                                >
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    {teamMemberData?.firstName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {teamMemberData?.lastName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {teamMemberData?.email}
                                  </TableCell>
                                  <TableCell align="center">
                                    {teamMemberData?.approvalAmountTo}
                                  </TableCell>

                                  <TableCell align="center">
                                    <Tooltip
                                      title="Delete"
                                      placement="top-end"
                                      arrow
                                      classes={{
                                        arrow: styleclasses.arrow,
                                        tooltip: styleclasses.tooltip,
                                      }}
                                    >
                                      <DeleteIcon
                                        data-userid={teamUserId}
                                        onClick={teamMemberhandledelete}
                                        style={{
                                          color: "#B4B4B4",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                          })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                      {loading && (
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            <Loader />
                          </TableCell>
                        </TableRow>
                      )}

                      {Data.length === 0 && !loading && (
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            <NoRecordFound />
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading &&
                        stableSort(Data, getComparator(order, orderBy))
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((datalist, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const { userId } = datalist;
                            let { isDeleted = 1 } =
                              apiAddignedTeam[userId] || {};
                            return (
                              <TableRow
                                hover
                                // onClick={(event) => handleClick(event, name)}
                                aria-checked={isDeleted == 0}
                                tabIndex={-1}
                                key={index}
                                selected={isDeleted == 0}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                  style={{ fontWeight: 450, fontSize: 14 }}
                                >
                                  {datalist?.firstName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: 450, fontSize: 14 }}
                                >
                                  {datalist?.lastName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: 450, fontSize: 14 }}
                                >
                                  {datalist?.email}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontWeight: 450, fontSize: 14 }}
                                >
                                  {datalist?.approvalAmountTo}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontWeight: 450, fontSize: 14 }}
                                >
                                  {" "}
                                  <Checkbox
                                    onClick={handleAssignSupplier}
                                    data-userid={userId}
                                    checked={isDeleted == 0}
                                    inputProps={{
                                      "aria-label": "Checkbox demo",
                                      "data-userid": userId,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                <Grid item xs={6} md={6} className={styleclasses.paginationrow}>
                  <Typography>
                    Rows Per Page:
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={teamFilter.count}
                        onChange={rowHandle}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  className={styleclasses.paginationitem}
                >
                  <Typography className={styleclasses.paginationnum}>
                    {teamFilter.offset * teamFilter.count + 1}-
                    {totalRecord < teamFilter.count * (teamFilter.offset + 1)
                      ? totalRecord
                      : teamFilter.count * (teamFilter.offset + 1)}{" "}
                    of {totalRecord} items
                    {teamFilter.offset > 0 && (
                      <KeyboardArrowLeftRoundedIcon
                        onClick={previorsPage}
                        className={styleclasses.iconpaginationleft}
                      />
                    )}
                    {totalRecord >
                      teamFilter.count * (teamFilter.offset + 1) && (
                      <ChevronRightRoundedIcon
                        onClick={nextPage}
                        className={styleclasses.iconpaginationright}
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid> */}
              </>
            )}
          </Paper>
          {addMemberFlag ? (
            <PagenationPages
              dataparams={assignListFilter}
              rowHandle={rowAssignHandle}
              totalRecord={totalAssignRecord}
              previorsPage={previorsAssignPage}
              nextPage={nextAssignPage}
            />
          ) : (
            <PagenationPages
              dataparams={teamFilter}
              rowHandle={rowHandle}
              totalRecord={totalRecord}
              previorsPage={previorsPage}
              nextPage={nextPage}
            />
          )}
        </Box>
      </Grid>

      <FilterTeam
        open={filterModal}
        handleClose={filterToggle}
        filterparams={dataparams}
        TeamfilterHandler={TeamfilterHandler}
      />
    </>
  );
}
