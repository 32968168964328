import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdlIitHzPkYaQzjZz5zd_aQalpLNdCmX0",
  authDomain: "ez-cloud-c8683.firebaseapp.com",
  projectId: "ez-cloud-c8683",
  storageBucket: "ez-cloud-c8683.appspot.com",
  messagingSenderId: "1044122700090",
  appId: "1:1044122700090:web:f2d325f415af0f59312803",
  measurementId: "G-53WQTJWNFG",
};
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();
const { REACT_APP_VAPID_KEY } =
  "BFW-YbgD9hPwrIfPUylcs6U6YmzjsGV1JBobq8gA2OyJ6az0Be0IxlFOCcacUdn-Yf77uHJ5USIT5PhbiEqCBNY";
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) { return null;
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
