import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Divider, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import API from "../constants/API";
import Swal from "sweetalert2";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
} from "../comman/function";
import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import { SUPPLIER_LISTING, UPDATE_SUPPLIER_STATUS } from "../comman/url";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import { Badge } from "reactstrap";
import { requestStatusStyleConfig } from "../constants/HelperConstant";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  tableContainerbg: {
    background: "white !important",
    // borderRadius: "4px !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
    overflow: "hidden",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white !important",
    border: "0px solid #ced4da",
    fontSize: 15,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "100% !important",
    [theme.breakpoints.up("sm")]: {
      width: "10ch",
      "&:focus": {
        width: "10ch",
      },
    },
  },
}));

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  let a_value = a[orderBy];
  let b_value = b[orderBy];
  if (orderBy === "companyName" || orderBy === "requestedEmail") {
    a_value = a_value?.toLowerCase();
    b_value = b_value?.toLowerCase();
  }

  if (b_value < a_value) {
    return -1;
  }
  if (b_value > a_value) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "companyName",
    numeric: true,
    disablePadding: false,
    label: "Company Name",
  },
  // {
  //   id: "requestedName",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Subscriber Name",
  // },
  {
    id: "requestedEmail",
    numeric: true,
    disablePadding: false,
    label: "Subscriber Email",
  },
  {
    id: "requestStatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "enable",
    numeric: true,
    disablePadding: false,
    label: "Enable",
  },
];
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={headCell.label !== "Enable" && "custom-border"}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.label === "Enable"}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
              {headCell.label === "Enable" && (
                <LightTooltip title="Enable/Disable" placement="bottom-start">
                  <InfoOutlinedIcon
                    style={{
                      color: "#A0A3BD",
                      width: "15px",
                      padding: "0px 1px",
                    }}
                  />
                </LightTooltip>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function MyClientsTable({
  tableData,
  requestListing,
  tableLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("supplieremail");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);
  const [statusLoading, setStatusLoading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.suppliername);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const statusChange = async (e, request_id) => {
    
    const { value } = e.target;
    if (value == "accept" && request_id) {
      setStatusLoading(true);
      const config = {
        method: "PUT",
        url: UPDATE_SUPPLIER_STATUS,
        headers: authHeader(),
        data: {
          // requestId: request_id,
          // status: "Accepted",
          webString: enCryptFun(
            JSON.stringify({
              requestId: request_id,
              status: "Accepted",
            })
          ),
          flutterString: "",
        },
      };
      try {
        const response = await API(config);
        //const { status } = response.data;
        let l = deCryptFun(response.data);
        const { status } = JSON.parse(l);
        if (status == "Success") {
          // Swal.fire(getAlertToast("Success", " Status Updated Successfully"));
          CustomMessage(
            "Invite accepted",
            "success",
            enqueueSnackbar
          ); //call toster
        }
        requestListing();
      } catch (error) {
        if (error.response) {
          let { data } = error.response;

          let p = deCryptFun(data);
          let v = JSON.parse(p);
          
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        }
      } finally {
        setStatusLoading(false);
      }
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "12px" }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className={styleclasses.tableContainerbg}>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
              />

              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {tableLoading && (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}
                {!tableLoading && tableData && tableData.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}

                {!tableLoading &&
                  stableSort(tableData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.requestId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          // onClick={(event) =>
                          //   handleClick(event, row.requestId)
                          // }
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.requestId}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {row.companyName}
                          </TableCell>
                          {/* <TableCell align="center">
                          {row.requestedName}
                        </TableCell> */}

                          <TableCell align="center">
                            {row.requestedEmail}
                          </TableCell>

                          <TableCell align="center">
                            <Badge
                              color={
                                requestStatusStyleConfig[row.requestStatus]
                              }
                            >
                              {row.requestStatus}
                            </Badge>
                          </TableCell>

                          <TableCell style={{ textAlign: "-webkit-center" }}>
                            {(row && row.requestStatus == "Accepted") ||
                            row.requestStatus == "Deactivated" ? (
                              ""
                            ) : (
                              <NativeSelect
                                id="demo-customized-select-native"
                                // labelId="demo-simple-select-label"
                                onChange={(e) => statusChange(e, row.requestId)}
                                label="Status Change"
                                input={
                                  <BootstrapInput
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                  />
                                }
                              >
                                <option value={""} style={{ fontSize: "14px" }}>
                                  Status Change
                                </option>
                                <option
                                  value={"accept"}
                                  style={{ fontSize: "14px" }}
                                >
                                  Accept
                                </option>
                              </NativeSelect>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
