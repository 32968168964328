import React, { Fragment, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Rocketmail from "../images/Rocketmail.png";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { SIGNIN_ROUTE } from "../constants/RoutePaths";
import { VERIFY_EMAIL_URL } from "../comman/url";
import { deCryptFun, enCryptFun } from "../comman/function";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";

const useStyles = makeStyles((theme) => ({
  shadow1: {
    width: "100%",
    backgroundColor: "unset !important",
    boxShadow: "none !important",
    padding: "24px",
    marginTop: "48px",
  },
  regimg: {
    width: "25%",
  },
  regtext: {
    // width: "72%",
    // display: "flex",
    margin: "auto !important",
    // [theme.breakpoints.up("md")]: {
    //   width: "100%",
    // },
  },
  pleasetext: {
    margin: "auto !important",
    fontSize: "14px !important",
    color: "#A0A3BD !important",
  },
  buttontext: {
    backgroundColor: "#EC7C37 !important",
    boxShadow: "none !important",
    "&:hover": {
      color: "#EC7C37 !important",
      border: "1px solid #EC7C37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
}));

export default function VerifyEmail(props) {
  let location = useLocation();
  const styleclasses = useStyles();
  let isFirsttime = useRef(true);
  let history = useNavigate();
  const { classes } = props;
  var searchParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const gotoSigin = () => {
    
    history(SIGNIN_ROUTE);
  };

  useEffect(() => {
    VerifyEmail();
  }, []);

  const VerifyEmail = async () => {
    let emailvalue = searchParams.get("email");
    let newEmailValue = decodeURIComponent(emailvalue.replace(" ", "+"));
    const config = {
      method: "PUT",
      url: VERIFY_EMAIL_URL,
      data: {
        // email: searchParams.get("email"),
        // hashKey: searchParams.get("hashKey"),
        webString: enCryptFun(
          JSON.stringify({
            email: newEmailValue,
            hashKey: searchParams.get("hashKey"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (status === "Success") {
        setData(
          "Thank you. You have successfully verified your email address with us"
        );
        //Swal.fire(getAlertToast("success", "Email Verified Successfully"));
        CustomMessage(
          "Email verified",
          "success",
          enqueueSnackbar
        );
      } else {
        setData(message);
        // Swal.fire(getAlertToast(message));
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        setData(v.message);
        
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  return (
    <Fragment>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          columnSpacing={{ xs: 4, sm: 4, md: 4 }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Item className={styleclasses.shadow1}>
              <img src={Rocketmail} className={styleclasses.regimg}></img>
            </Item>

            <Grid item xs={12} md={12} lg={12} className={styleclasses.regtext}>
              <Typography align="center" variant="h6">
                {data}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ p: 2, fontWeight: 600 }}>
              <Typography align="center" className={styleclasses.pleasetext}>
                Please check your spam/junk folder if you do not see any
                messages from EZ Cloud in your inbox.
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12} sx={{ p: 2, fontWeight: 600 }}>
              <Button
                className={styleclasses.buttontext}
                variant="contained"
                onClick={() => gotoSigin()}
              >
                Signin
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}
