import React, { useState, useEffect } from "react";
import { Box, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Mainlogo from "../images/Logo_Color1.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { deCryptFun, enCryptFun } from "../comman/function";
import { GET_TEAMDETAILS_BY_ID, LOGIN_URL, SAVE_DEVICE } from "../comman/url";
import API from "../constants/API";
import { UPDATE_COMPANY_LOGO, UPDATE_USER_PROFILE, } from "../redux/action/ActionType";
import { DASHBOARD_ROUTE, FORGOTPASSWORD_ROUTE, SIGNUP_ROUTE, UPDATE_PASSWORD_ROUTE } from "../constants/RoutePaths";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../firebase"

const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 50px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
  },
  loginbtn: {
    backgroundColor: "#3779EC !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    "&:hover": {
      color: "#3779EC !important",
      border: "1px solid #3779EC !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
}));

function Signin() {

  const styleclasses = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { state = "" } = location;
  const navigation = useNavigate();
  const [PasswordToggle, setPasswordToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isTokenFound, setTokenFound] = useState(false);

  const saveFormValues = async (values) => {
    setLoading(true);
    const config = {
      method: "POST",
      url: LOGIN_URL,
      data: {
        webString: enCryptFun(JSON.stringify(values)),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data, invoiceCount, exceptionRoleId } = JSON.parse(l);
      const { accesstoken } = response.headers;

      if (status === "Success") {
        if (data.isDefaultPassword == "0") {
          localStorage.setItem("User_ID", data.userId);
          localStorage.setItem("AUTH_TOKEN", accesstoken);
          localStorage.setItem("Team_ID", data.teamId);
          localStorage.setItem("EMAIL", data.email);
          localStorage.setItem("SENDER_EMAIL", data.invoiceSenderEmail);
          localStorage.setItem("USER_ROLE", data.userRole);
          localStorage.setItem("PROFILE_PIC", data.profileLogo);
          localStorage.setItem("APPROVAL_AMOUNT", data.approvalAmountTo);
          localStorage.setItem("INVOICE_COUNT", invoiceCount);
          localStorage.setItem("BRAND_LOGO", data.companyLogo);
          localStorage.setItem("AUTH_USER_DETAILS", JSON.stringify(data));
          localStorage.setItem("AUTH_2F", 0);
          localStorage.setItem("isException", exceptionRoleId);
          localStorage.setItem("isAddSupplier", data.isAddSupplier)
          localStorage.setItem("LOGIN_NAME", data.firstName + " " + data.lastName);
          dispatch({
            type: UPDATE_USER_PROFILE,
            payload: data.profileLogo,
            userName: data.firstName + " " + data.lastName,
          });
          dispatch({
            type: UPDATE_COMPANY_LOGO,
            payload: data.companyLogo,
          });
        }
        if (data.isDefaultPassword == "1") {
          navigation(UPDATE_PASSWORD_ROUTE, {
            state: {
              data: data,
              accesstoken: accesstoken,
              invoiceCount: invoiceCount,
              exceptionRoleId: exceptionRoleId
            },
          });
        }
        else {
          if (state) {
            navigation(state.replace(window.location.origin, ""));
          }
          else {
            navigation(DASHBOARD_ROUTE);
            getTeamDetailsById(data.teamId);
            // saveDevice(data.userId);
          }
        }
      }
      else {
        console.error("error");
      }
    }
    catch (error) {
      if (error.response) {
        let { data } = error.response;
        let p = deCryptFun(data);
        let v = JSON.parse(p);
        if (v.accountExist !== 1) {
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
          navigation(SIGNUP_ROUTE);
        } else {
          CustomMessage(v.message, "error", enqueueSnackbar); //call toster
        }
      }
    }
    finally {
      setLoading(false);
    }
  };

  const getTeamDetailsById = async (id) => {
    const config = {
      method: "GET",
      url: GET_TEAMDETAILS_BY_ID,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: id,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        localStorage.setItem("Suppliermaintanceemail", data[0].supplierMaintenanceEmail);

      }
    }
    catch (error) {
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar);
      }
    }
    finally { }
  };

  const saveDevice = async (id) => {
    const config = {
      method: "PUT",
      url: SAVE_DEVICE,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            userId: id,
            deviceId: localStorage.getItem("Device_ID"),
            isActive: true
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      
      if (status === "Success") {
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
      }
    } finally { }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: (values) => saveFormValues(values),
    });

  let Toogle = () => {
    setPasswordToggle(!PasswordToggle);
  };

  const forGotpassword = () => {
    navigation(FORGOTPASSWORD_ROUTE);
  };

  const gotoSignup = () => {
    navigation(SIGNUP_ROUTE);
  };


  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        
        localStorage.setItem("Device_ID", data);
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container sx={{ height: "100vh" }} rowSpacing={1} justifyContent="center" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid sx={{ margin: "auto" }} xs={12} item md={4}>
            <Box>
              <img src={Mainlogo} style={{ marginTop: "30px", marginBottom: "20px" }}></img>
            </Box>
            <Box className={styleclasses.loginbox} component={"form"} onSubmit={handleSubmit}>
              <Typography sx={{ mt: 1, mb: 3, fontWeight: "600" }} align="center" variant="h4">
                LOGIN
              </Typography>
              <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
                Email
                <Box component={"span"} sx={{ color: "#F14A4A !important" }}>*</Box>
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={"text"}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  onBlur={handleBlur}
                  error={errors.email && touched.email ? true : false}
                />
                <FormHelperText error={true}>
                  {errors.email && touched.email ? errors.email : ""}
                </FormHelperText>
              </FormControl>
              <Typography gutterBottom sx={{ mt: 2, fontWeight: 600, color: "#4E4B66" }}>
                Password
                <Box component={"span"} sx={{ color: "#F14A4A !important" }}> *</Box>
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={PasswordToggle ? "password" : "text"}
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  placeholder="Enter Password"
                  error={errors.password && touched.password ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={Toogle}
                        edge="end"
                      >
                        {PasswordToggle ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={true}>
                  {errors.password && touched.password ? errors.password : ""}
                </FormHelperText>
              </FormControl>
              <Box sx={{ textAlign: "right" }}>
                <Button
                  sx={{
                    mt: 2,
                    mb: 3,
                    fontWeight: "600",
                    textDecoration: "none",
                    color: "#ec7c37",
                    textTransform: "capitalize",
                    padding: "0",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  color="#ec7c37"
                  variant="body2"
                  onClick={forGotpassword}
                >
                  Forgot Password?
                </Button>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={loading}
                  className={styleclasses.loginbtn}
                  type="submit"
                >
                  Sign In
                </Button>
              </Box>
            </Box>
            <Typography sx={{ mt: 2 }}>
              Don't have an account?
              <Link
                sx={{ ml: 1, fontWeight: "600", textDecoration: "none" }}
                color="#793ED6"
                component="button"
                variant="body2"
                onClick={gotoSignup}
              >
                Sign Up
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Signin;
