import React, {
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Modal,
  ModalFooter,
  ModalBody,
  CardHeader,
  CardBody,
  Card,
  UncontrolledCollapse,
  FormGroup,
  Label,
  Input,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Box from "@mui/material/Box";	
import Tab from "@mui/material/Tab";	
import TabContext from "@mui/lab/TabContext";	
import TabList from "@mui/lab/TabList";	
import TabPanel from "@mui/lab/TabPanel";
import { useSnackbar } from "notistack";

import Button from "@mui/material/Button";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  COMMENT_BY_ID_URL,
  COMMENT_LIST_URL,
  COMMENT_USER,
  CREATE_COMMENT_URL,
  DELETE_COMMENT_URL,
  SAVE_NOTIFICATION_DETAILS,
  GET_USERS,
  UPDATE_COMMENT_URL,
} from "../comman/url";
import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import { CustomMessage } from "../comman/helperfunction";
import API from "../constants/API";
import Swal from "sweetalert2";
import Loader from "../comman/Loader";
import {
  deCryptFun,
  enCryptFun,
  getLoginName,
  getProfilePic,
  getTeamID,
  getUserId,
  getRole,
} from "../comman/function";
import moment from "moment";
import "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { getNotificationDetails } from "../redux/action";
import { useDispatch } from "react-redux";

export default function ChatSection(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const idc = open ? "simple-popover" : undefined;

  const { Toggle1, chatFun, chatFlag, invoiceID, email } = props;
  const [createLoading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [id, setId] = useState();
  const [userId, setUserId] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [count, setCount] = useState(0);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [commentCount, setcommentCount] = useState();
  const [value, setValue] = React.useState(
    ["Supplier", "Sub Supplier"].includes(getRole()) ? "2" : "1"
  );
  const dispatch = useDispatch();
  const inputRef = useRef();

  const createComment = async (values) => {
    setLoading(true);
    const config = {
      method: id ? "PUT" : "POST",
      url: id ? UPDATE_COMMENT_URL : CREATE_COMMENT_URL,
      headers: authHeader(),
      // data: {
      //   postedBy: getUserId(),
      //   comment: commentValue,
      //   tagBy: userId ? userId.join(",") : 0,
      //   invoiceId: invoiceID,
      //   commentId: id ? id : "",
      // },
      data: {
        webString: enCryptFun(
          JSON.stringify({
            postedBy: getUserId(),
            comment: commentValue,
            tagBy: userId ? userId.join(",") : 0,
            invoiceId: invoiceID,
            commentId: id ? id : "",
            isSupplierTab: value == 1 ? 1 : 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, commentId } = JSON.parse(l);
      //Swal.fire(getAlertToast("success", message));
      saveNotification(userId ? userId.join(",") : 0, commentValue, commentId);
      getComment();
      CustomMessage(message, "success", enqueueSnackbar);

      setLoading(false);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire(getAlert("Error", v.message));
      }
    } finally {
      setLoading(false);
      setComment("");
      setId("");
      setUserId("");
    }
  };

  const saveNotification = async (userid, commentValue, commentId) => {
    const keycongigure = {
      method: "POST",
      url: SAVE_NOTIFICATION_DETAILS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            notificationDetailsId: 0,
            userId: userid ? userid : getUserId(),
            tag: "Comments",
            invoiceId: invoiceID,
            payload: "You have been tagged in comments on this invoice",
            commentId: commentId,
            isRead: true,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);
      dispatch(getNotificationDetails);
    } catch (error) { 
    } finally {
      dispatch(getNotificationDetails);
    }
  };

  const [commentList, setList] = useState();

  const getComment = async (value) => {
    setGetLoading(true);
    const getconfig = {
      method: "GET",
      url: COMMENT_LIST_URL,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        // postedBy: value ? value : "",
        // invoiceId: invoiceID,
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            postedBy: value ? value : "",
            invoiceId: invoiceID,
            count: commentCount,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfig);
      // const { status, message, data, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data, count } = JSON.parse(l);
      setList(data);
      setcommentCount(count);
      setCount(count);
      setGetLoading(false);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire(getAlert("Error", v.message));
        setGetLoading(false);
      }
    } finally {
      setGetLoading(false);
    }
  };
  const getCommentById = async (commentid) => {
    const getconfigid = {
      method: "GET",
      url: COMMENT_BY_ID_URL,
      headers: authHeader(),
      params: {
        // commentId: commentid,
        webString: enCryptFun(
          JSON.stringify({
            commentId: commentid,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(getconfigid);
      // const { status, message, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data } = JSON.parse(l);
      const { comment } = data;
      setComment(comment);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };

  useEffect(() => {
    getComment();
  }, [commentCount]);

  const validationSchema = Yup.object().shape({
    // Comment: Yup.string().required("Required"),
  });

  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        Comment: "",
      },
      validationSchema,
      onSubmit: (values) => createComment(values),
    });

  const deleteRequest = async (commentid) => {
    const deleteconfig = {
      method: "DELETE",
      url: DELETE_COMMENT_URL,
      headers: authHeader(),
      params: {
        // commentId: commentid,
        webString: enCryptFun(
          JSON.stringify({
            commentId: commentid,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(deleteconfig);
      // const { status, message, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data } = JSON.parse(l);
      getComment();
      CustomMessage(data, "success", enqueueSnackbar);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  const deleteCommentFun = async (e) => {
    const commentid = e.currentTarget.dataset.commentid;
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this comment?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        deleteRequest(commentid);
      }
    });
  };
  const editFun = async (e) => {
    const commentid = e.currentTarget.dataset.commentid;
    getCommentById(commentid);
    setId(commentid);
  };

  const [commentValue, setComment] = useState();

  const [tagFlag, setTagFlag] = useState(false);
  const toggleFun = (e) => {
    setTagFlag(!tagFlag);
  };
  const [tagFlagSupplier, setTagFlagSupplier] = useState(false);
  const handlesChange = (event, newValue) => {
    setValue(newValue);
    setComment("");
  };
  const toggleFunSup = (e) => {
    setTagFlagSupplier(!tagFlagSupplier);
  };
  const handleChangeFun = (e) => {
    setComment(e.target.value);
    if (!e.target.value) {
      setTagFlag(false);
    }
    if (e.target.value.slice(-1) === "@") {
      // getUsers();

      setUserList(userList1);
      setTagFlag(true);
    } else {
      const chkValue = e.target.value.split("@").pop();
      const result = userList.filter((record) => {
        let fullname = record.firstName.concat(" ", record.lastName);
        let ignoreCase = fullname;
        return ignoreCase.toLowerCase().startsWith(chkValue.toLowerCase());
      });
      setUserList(result);
      if (e.target.value.charAt() === "@") {
        setTagFlag(true);
      }
    }

    handleChange(e);
  };
  const handleChangeFunSuppl = (e) => {
    setComment(e.target.value);
    if (!e.target.value) {
      setTagFlagSupplier(false);
    }
    if (e.target.value.slice(-1) == "@") {
      // getUsers();
      setUsersuppList(usersupplierList1);
      setTagFlagSupplier(true);
    } else {
      const chkValue = e.target.value.split("@").pop();
      const result = usersupplierList.filter((record) => {
        let fullname = record.firstName.concat(" ", record.lastName);
        let ignoreCase = fullname.toLowerCase();
        return ignoreCase.startsWith(chkValue);
      });
      setUsersuppList(result);
    }
    handleChange(e);
  };
  const cancelFun = () => {
    setId("");
    setComment("");
  };
  const [userList, setUserList] = useState();
  const [userList1, setUserList1] = useState();

  const getUsers = async () => {
    const userConfig = {
      method: "GET",
      url: COMMENT_USER,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            teamId: ["Supplier", "Sub Supplier"].includes(getRole())
              ? ""
              : getTeamID(),
            invoiceSenderEmail: ["Supplier", "Sub Supplier"].includes(getRole())
              ? email
              : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(userConfig);
      //const { status, message, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data } = JSON.parse(l);
      const result1 = data.filter((record) => record.userId != getUserId());
      // const uslist = userList.filter((record) => record.userId != e.target.value);
      // if (userId.length > 0) {
      //   userId.map((rec, i) => {
      //     let neli=[]
      //     const uslist = userList.filter((record) => record.userId != rec);
      //   });
      // }
      setUserList(result1);
      setUserList1(result1);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  const [usersupplierList, setUsersuppList] = useState();
  const [usersupplierList1, setUsersuppList1] = useState();
  const getUsersSupplier = async () => {
    const userConfig = {
      method: "GET",
      url: GET_USERS,
      headers: authHeader(),
      params: {
        // invoiceId: invoiceID,
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            // teamId: getTeamID(),
            offset: 0,
            count: 100,

            invoiceSenderEmail: email,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(userConfig);
      //const { status, message, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, message, data } = JSON.parse(l);
      setUsersuppList(data);
      setUsersuppList1(data);
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire(getAlert("Error", v.message));
      }
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    getUsersSupplier();
  }, []);
  const userChange = (e) => {
    const firstname = e.currentTarget.dataset.firstname;
    const lastname = e.currentTarget.dataset.lastname;
    const fullname = firstname.concat(" ", lastname);
    setUserId([...userId, e.target.value]);

    const nr = commentValue.split("@").pop();
    const anew = commentValue.replace(nr, "");
    let newstr = "";
    if (anew) {
      newstr = anew.concat(fullname," ");
    }
    setComment(newstr);
    inputRef.current.focus();
    setTagFlag(false);
    // getUsers();
    // const uslist = userList.filter((record) => record.userId != e.target.value);
    // const uslist1 = userList1.filter(
    //   (record) => record.userId != e.target.value
    // );

    // setUserList(uslist);
    // setUserList1(uslist1);
  };
  const userChangeSupplier = (e) => {
    const firstname = e.currentTarget.dataset.firstname;
    const lastname = e.currentTarget.dataset.lastname;
    const fullname = firstname.concat(" ", lastname);
    setUserId([...userId, e.target.value]);

    const nr = commentValue.split("@").pop();
    const anew = commentValue.replace(nr, "");
    let newstr = "";
    if (anew) {
      newstr = anew.concat(fullname);
    }
    setComment(newstr);

    setTagFlagSupplier(false);
    // getUsers();
    const uslist = userList.filter((record) => record.userId != e.target.value);
    const uslist1 = userList1.filter(
      (record) => record.userId != e.target.value
    );

    setUsersuppList(uslist);
    setUsersuppList1(uslist1);
  };

  let keyPress = (e) => {
    if (e.keyCode == 13 && e.target.value != "") {
      setLoading(true);
      createComment(e.target.value);
      setComment("");
    }
  };

  return chatFlag === true ? (
    <div className="chat-screen">
    <div className="chat-header clearfix">
      {/* <img
        src={getProfilePic()}
        alt="avatar"
      /> */}

      <div className="chat-about">
        <div className="chat-with">{getLoginName()}</div>
      </div>

      {/* <svg
          id="toggler"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z" />
        </svg> */}
      <Button
        sx={{
          float: "right",
          position: "absolute",
          right: "0",
          "&:hover": {
            backgroundColor: "unset !important",
          },
        }}
        color="secondary"
        size="sm"
        className="mr-2"
        onClick={id ? cancelFun : chatFun}
      >
        <CloseIcon sx={{ color: "#a8a8a8" }} />
        {/* {id ? "Cancel" : "Close"} */}
      </Button>
    </div>
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handlesChange} aria-label="lab API tabs example">
          {!["Supplier", "Sub Supplier"].includes(getRole()) &&
            <Tab
              label={ "Team Members"
              }value="1" />}
            <Tab
              label={
                ["Supplier", "Sub Supplier"].includes(getRole())
                  ? "Subscriber"
                  : "Supplier"
              }
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <div className="chat-history">
            <div className="chatbody">
              {getLoading && <Loader />}
              {commentList &&
                commentList.map((record, index) => {
                  const {
                    comment,
                    postDate,
                    postedByName,
                    postedByLogo,
                    commentId,
                    exceptionComments,
                    isSupplierTab,
                  } = record;

                  // var gmtDateTime = moment.utc(postDate, "YYYY-MM-DD");
                  // var convert12Date = gmtDateTime.local().format("YYYY-MM-DD");

                  // let convert12Time = moment(postDate, "HH:mm").format("hh:mm A");
                  // const currentTime = new Date(postDate).toISOString();
                  // var localTime = moment.utc(postDate).toDate();

                  // var gmtDateTime = moment.utc(localTime, "YYYY-MM-DD");
                  // var convert12Date = gmtDateTime
                  //   .local()
                  //   .format("YYYY-MM-DD");

                  // let convert12Time = moment(localTime, "HH:mm").format(
                  //   "hh:mm A"
                  // );
                  // const localdata = new Date();
                  // const dateAsString = localdata.toString();
                  // const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
                  // let timex = moment().tz(timezone).format();

                  return (
                    <>
                      {isSupplierTab == 1 ? (
                        postedByName == getLoginName() ? (
                          // <div className="chat-history">
                          <ul className="chatlist-right">
                            <Avatar src={postedByLogo} className="chatimg" />
                            <li className="chattext cmd">
                              {exceptionComments != null && (
                                <span>
                                  <b>{exceptionComments.replace("@", "")}</b>
                                  <br />
                                </span>
                              )}
                            </li>
                            <li
                              className="chattext"
                              aria-describedby={id}
                              variant="contained"
                            >
                              <div className="message other-message float-right">
                                {comment}
                                <div className="actionbtn">
                                  <Button
                                    sx={{ minWidth: "unset" }}
                                    // color="link"
                                    onClick={deleteCommentFun}
                                    data-commentid={commentId}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#fff"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                    </svg>
                                  </Button>
                                  <Button
                                    sx={{ minWidth: "unset" }}
                                    // color="link"
                                    onClick={editFun}
                                    data-commentid={commentId}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#fff"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </li>

                            <li className="chattext time">
                            {moment
                          .parseZone(postDate)
                          .format("hh:mm A, MM/DD/YYYY")} 
                            </li>
                          </ul>
                        ) : (
                          <ul className="chatlist-left">
                            <Avatar src={postedByLogo} className="chatimg" />
                            <li className="chattext cmd">
                              {exceptionComments != null && (
                                <span>
                                  {exceptionComments.replace("@", "")}
                                  <br />
                                </span>
                              )}
                            </li>
                            <li className="chattext">{comment} </li>
                            <li className="chattext time">
                              {" "}
                              {moment
                          .parseZone(postDate)
                          .format("hh:mm A, MM/DD/YYYY")} 
                            </li>
                          </ul>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>
            <div class="chat-message clearfix">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "60px",
                  boxShadow: "none",
                  border: "1px solid #8080803d",
                  borderRadius: "7px",
                }}
              >
                <InputBase
                  onChange={handleChangeFun}
                  value={commentValue}
                  sx={{ ml: 1, flex: 1 }}
                  onKeyDown={keyPress}
                  inputRef={inputRef}
                  placeholder="Write something..."
                  inputProps={{ "aria-label": "search google maps" }}
                />

                <IconButton
                  type="submit"
                  sx={{ p: "10px", transform: "rotateZ(45deg)" }}
                  aria-label="search"
                  disabled="disbled"
                >
                  <AttachFileIcon />
                </IconButton>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                <IconButton
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  disabled={createLoading}
                >
                  <SendIcon sx={{ color: createLoading ? "#A0A3BD" : "" }} />
                </IconButton>
              </Paper>
              <FormGroup></FormGroup>
            </div>
            {tagFlag == true && userList.length > 0 ? (
              <Dropdown isOpen={tagFlag} toggle={toggleFun}>
                <DropdownToggle>
                  <DropdownMenu
                    className="fromchat"
                    style={{
                      overflowY: "scroll",
                      maxHeight: "100px",
                      position: "left",
                    }}
                  >
                    <DropdownItem header>Select Chat Person</DropdownItem>
                    {userList &&
                      userList.map((record, index) => {
                        const { firstName, lastName, userId } = record;
                        return (
                          <DropdownItem
                            key={`user_listing_${index}`}
                            onClick={userChange}
                            value={userId}
                            data-firstname={firstName}
                            data-lastname={lastName}
                            hover
                          >
                            {firstName} {lastName}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </DropdownToggle>
              </Dropdown>
            ) : (
              ""
            )}{" "}
          </div>
        </TabPanel>
        <TabPanel sx={{ p: 0 }} value="2">
          {/* <ChatSupplierMod /> */}
          <div className="chat-history">
            <div className="chatbody">
              {getLoading && <Loader />}
              {commentList &&
                commentList.map((record, index) => {
                  const {
                    comment,
                    postDate,
                    postedByName,
                    postedByLogo,
                    commentId,
                    exceptionComments,
                    isSupplierTab,
                  } = record;

  
                  // var localTime = moment.utc(postDate).toDate();

                  // var gmtDateTime = moment.utc(localTime, "YYYY-MM-DD");
                  // var convert12Date = gmtDateTime
                  //   .local()
                  //   .format("YYYY-MM-DD");

                  // let convert12Time = moment(localTime, "HH:mm").format(
                  //   "hh:mm A"
                  // );
                  // const localdata = new Date();
                  // const dateAsString = localdata.toString();
                  // const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
                  // let timex = moment().tz(timezone).format();

                  return (
                    <>
                      {isSupplierTab!=1? (
                        postedByName == getLoginName() ? (
                          // <div className="chat-history">
                          <ul className="chatlist-right">
                            <Avatar src={postedByLogo} className="chatimg" />
                            <li className="chattext cmd">
                              {exceptionComments != null && (
                                <span>
                                  <b>{exceptionComments.replace("@", "")}</b>
                                  <br />
                                </span>
                              )}
                            </li>
                            <li
                              className="chattext"
                              aria-describedby={id}
                              variant="contained"
                            >
                              <div className="message other-message float-right">
                                {comment}
                                <div className="actionbtn">
                                  <Button
                                    sx={{ minWidth: "unset" }}
                                    // color="link"
                                    onClick={deleteCommentFun}
                                    data-commentid={commentId}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#fff"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                    </svg>
                                  </Button>
                                  <Button
                                    sx={{ minWidth: "unset" }}
                                    // color="link"
                                    onClick={editFun}
                                    data-commentid={commentId}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#fff"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </li>

                            <li className="chattext time">
                             
                      {moment
                        .parseZone(postDate)
                        .format("hh:mm A, MM/DD/YYYY")} 
                            </li>
                          </ul>
                        ) : (
                          <ul className="chatlist-left">
                            <Avatar src={postedByLogo} className="chatimg" />
                            <li className="chattext cmd">
                              {exceptionComments != null && (
                                <span>
                                  {exceptionComments.replace("@", "")}
                                  <br />
                                </span>
                              )}
                            </li>
                            <li className="chattext">{comment} </li>
                            <li className="chattext time">
                              {" "}
                              {moment
                        .parseZone(postDate)
                        .format("hh:mm A, MM/DD/YYYY")} 
                            </li>
                          </ul>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>
            <div class="chat-message clearfix">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "60px",
                  boxShadow: "none",
                  border: "1px solid #8080803d",
                  borderRadius: "7px",
                }}
              >
                <InputBase
                  onChange={handleChangeFunSuppl}
                  value={commentValue}
                  sx={{ ml: 1, flex: 1 }}
                  onKeyDown={keyPress}
                  ref={inputRef}
                  placeholder="Write something..."
                  inputProps={{ "aria-label": "search google maps" }}
                />

                <IconButton
                  type="submit"
                  sx={{ p: "10px", transform: "rotateZ(45deg)" }}
                  aria-label="search"
                  disabled="disbled"
                >
                  <AttachFileIcon />
                </IconButton>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                <IconButton
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  disabled={createLoading}
                >
                  <SendIcon sx={{ color: createLoading ? "#A0A3BD" : "" }} />
                </IconButton>
              </Paper>
              <FormGroup>
                {/* <Input
          type="textarea"
          name="comment"
          id="comment"
          onChange={handleChangeFun}
          value={commentValue}
        /> */}
              </FormGroup>
            </div>
            {tagFlagSupplier == true && usersupplierList.length > 0 ? (
              <Dropdown isOpen={tagFlagSupplier} toggle={toggleFunSup}>
                <DropdownToggle>
                  <DropdownMenu
                    className="fromchat"
                    style={{
                      overflowY: "scroll",
                      maxHeight: "100px",
                      position: "left",
                    }}
                  >
                    <DropdownItem header>Select Chat Person</DropdownItem>
                    {usersupplierList &&
                      usersupplierList.map((record, index) => {
                        const { firstName, lastName, userId } = record;
                        return (
                          <DropdownItem
                            key={`user_listing_${index}`}
                            onClick={userChangeSupplier}
                            value={userId}
                            data-firstname={firstName}
                            data-lastname={lastName}
                            hover
                          >
                            {firstName} {lastName}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </DropdownToggle>
              </Dropdown>
            ) : (
              ""
            )}{" "}
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  </div>
  ) : (
    ""
  );
}
