import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Mainlogo from "../images/Logo_Color1.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import { VALIDATE_TWO_FACTOR_AUTH } from "../comman/url";
import { deCryptFun, enCryptFun, getEmail } from "../comman/function";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, SIGNUP_ROUTE } from "../constants/RoutePaths";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import API from "../constants/API";
import { authHeader } from "../comman/mainfunction";
import {
  UPDATE_COMPANY_LOGO,
  UPDATE_USER_PROFILE,
} from "../redux/action/ActionType";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { NUMBER_REGEX } from "../comman/constants";

const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 50px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
  },
}));

const Input = styled("input")({
  display: "none",
});

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

function Authentification() {
  let navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const location = useLocation();
  const { state = {} } = location;
  const dispatch = useDispatch();
  const {
    data = {},
    accesstoken = "",
    invoiceCount = 0,
    email = "",
    url = "",
  } = state || {};

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateTwoFactor = async (values) => {
    const config = {
      method: "POST",
      url: VALIDATE_TWO_FACTOR_AUTH,
      headers: authHeader(),
      data: {
        // otpType: "Phone",
        // otpTo: Number,
        // countryCode: code,
        webString: enCryptFun(
          JSON.stringify({
            ...values,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { status, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (status === "Success") {
        CustomMessage("Validated Successfully", "success", enqueueSnackbar); //call toster
        // setconfirmModal(true);
        // localStorage.setItem("User_ID", data.userId);
        // localStorage.setItem("AUTH_TOKEN", accesstoken);
        // localStorage.setItem("Team_ID", data.teamId);
        // localStorage.setItem("EMAIL", data.email);
        // localStorage.setItem("SENDER_EMAIL", data.invoiceSenderEmail);
        // localStorage.setItem("USER_ROLE", data.userRole);
        // localStorage.setItem("PROFILE_PIC", data.profileLogo);
        // localStorage.setItem("APPROVAL_AMOUNT", data.approvalAmountTo);
        // localStorage.setItem("INVOICE_COUNT", invoiceCount);
        // localStorage.setItem("BRAND_LOGO", data.companyLogo);
        // localStorage.setItem("AUTH_USER_DETAILS", JSON.stringify(data));
        // localStorage.setItem("AUTH_2F", 0);
        // localStorage.setItem("isException",data.tblExceptionHandlerRoleId);

        // localStorage.setItem(
        //   "LOGIN_NAME",
        //   data.firstName + " " + data.lastName
        // );
        // dispatch({
        //   type: UPDATE_USER_PROFILE,
        //   payload: data.profileLogo,
        //   userName: data.firstName + " " + data.lastName,
        // });
        // dispatch({
        //   type: UPDATE_COMPANY_LOGO,
        //   payload: data.companyLogo,
        // });
        // if (url) {
        //   navigation(url);
        // } else {
        //   navigation(DASHBOARD_ROUTE);
        // }
      } else {
        CustomMessage(message, "error", enqueueSnackbar); //call toster
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    twoFaAuthenticationCode: Yup.string()
      .matches(NUMBER_REGEX, "2FA Authentication code must be in number")
      .min("6", "Please enter the six digit code")
      .max("6", "Please enter the six digit code")
      .required("Required"),
  });
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    resetForm,
    errors,
    touched,
  } = useFormik({
    initialValues: { email: email, twoFaAuthenticationCode: "" },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => validateTwoFactor(values),
  });
  const gotoSignup = () => {
    navigation(SIGNUP_ROUTE);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          sx={{ height: "100vh" }}
          container
          rowSpacing={1}
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid sx={{ margin: "auto" }} xs={12} md={4}>
            <Box>
              <img
                alt=""
                src={Mainlogo}
                style={{ marginTop: "30px", marginBottom: "20px" }}
              ></img>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box component={"form"} className={styleclasses.loginbox}>
                <Typography
                  sx={{ mt: 1, mb: 3, fontWeight: "600" }}
                  align="center"
                  variant="h5"
                >
                  2FA Authentification
                </Typography>
                <Typography align="center" paragraph color="gray">
                  Please enter 2FA Authentification code that you remember
                </Typography>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <TextField
                    id="outlined-required"
                    error={
                      errors.twoFaAuthenticationCode &&
                      touched.twoFaAuthenticationCode
                    }
                    name="twoFaAuthenticationCode"
                    onBlur={handleBlur}
                    value={values.twoFaAuthenticationCode}
                    onChange={handleChange}
                    helperText={
                      errors.twoFaAuthenticationCode &&
                      touched.twoFaAuthenticationCode
                        ? errors.twoFaAuthenticationCode
                        : ""
                    }
                    label="Two Factor Authentication Code *"

                    // defaultValue="Hello World"
                  />
                </FormControl>

                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button variant="contained" type="submit">
                    Validate
                  </Button>
                </Box>
              </Box>
            </form>

            <Typography sx={{ mt: 2 }}>
              Don't have an account?
              <Link
                sx={{ ml: 1, fontWeight: "600", textDecoration: "none" }}
                color="#ec7c37"
                component="button"
                variant="body2"
                onClick={gotoSignup}
              >
                Signup
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Authentification;
