import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Stack, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import { getTeamID, enCryptFun, deCryptFun } from "../comman/function";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";

import { arrayMoveImmutable } from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import {
  GET_ASSIGNED_GLCODE_VALUE,
  IMPORT_GLCODE,
  GET_TAG_VALUE,
  SAVE_GLCODE_TAGVALUE,
  GET_INVOICE_FIELDS,
} from "../comman/url";
import { produce } from "immer";
import API from "../constants/API";
import { REQUIRED_INVOICE } from "../redux/action/ActionType";
const useStyles = makeStyles((theme) => ({
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  tabletitles: {
    color: "#14142A",
    fontSize: "13px !important",
    display: "flex",
    alignItems: "center",
    float: "right",
    textAlign: "-webkit-center",
  },
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  notifydownloadbtn: {
    margin: "12px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white !important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 13,
//   },
// }));

export default function SettingsTable(props) {
  const { classes, editFormState } = props;
  // const [invoiceList, setInvoice] = useState([]);
  const invoiceList = useSelector((state) => state.Requiredinvoice);
  const [exportCheckList, setExportCheckList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [exportCount, setExportCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceLineList, setInvoiceLine] = useState([]);
  const dispatch = useDispatch();
  const styleclasses = useStyles();
  const changeSortOrderInvoice = async ({ oldIndex, newIndex }) => {
    let fieldArray = [...invoiceList];

    fieldArray = arrayMoveImmutable(fieldArray, oldIndex, newIndex);
    fieldArray.forEach((element, index) => (element.fieldOrder = index + 1));
    dispatch({ type: REQUIRED_INVOICE, payload: [...fieldArray] });
  };
  const handleSortStartInvoice = ({ node }) => {


    const tds = document.getElementsByClassName("SortableHelper")[0].childNodes;
    node.childNodes.forEach(
      (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`)
    );
  };

  const handleChange = (e) => {

    const { checked } = e.target;
    const { name, id } = e.currentTarget.dataset;
    let cloneInvoiceData = [...invoiceList];
    const updatedInvoiceArray = produce(cloneInvoiceData, (draft) => {
      const index = draft.findIndex((todo) => todo.fieldListId == id);
      if (index !== -1) draft[index][name] = checked ? 1 : 0;
    });
    dispatch({ type: REQUIRED_INVOICE, payload: updatedInvoiceArray });
  };
  const visibleHandel = (e) => {
    // if (!editFormState) {
    //   return;
    // }
    const { isvisible, id } = e.currentTarget.dataset;
    let cloneInvoiceData = [...invoiceList];
    const updatedInvoiceArray = produce(cloneInvoiceData, (draft) => {
      const index = draft.findIndex((todo) => todo.fieldListId == id);
      if (index !== -1) {
        draft[index].isVisible = isvisible;
        draft[index]["isRequired"] = 0;
        draft[index]["isExport"] = 0;
      }
    });
    dispatch({ type: REQUIRED_INVOICE, payload: updatedInvoiceArray });
  };
  const InvoiceSortableItem = sortableElement(({ data, key }) => {
    const {
      isMandatory,
      fieldName,
      fieldListId,
      isVisible,
      isRequired,
      fieldOrder,
      isActive,
      dataFormat,
      isExport,
    } = data;

    let recordFieldName = (name) => {

      if (name == "Vendor/Supplier Name") {
        return "Supplier Name";
      } else if (name == "Vendor/Supplier Number") {
        return "Supplier Phone";
      } else if (name == "Invoice Description") {
        return "Comments";
      } else if (name == "Vendor/Supplier Site") {
        return "Supplier Site";
      } else if (name == "Vendor/Supplier Addresss") {
        return "Supplier Address";
      } else if (name == "Vendor/Supplier Bank Account") {
        return "Supplier Bank Account";
      } else if (name == "Vendor/Supplier Tax Number") {
        return "Supplier Tax Number";
      } else if (name == "Invoice Amount") {
        return "Sub Total";
      } else if (name == "Due Amount") {
        return "Invoice Total";
      } else if (name == "Purchase Order Number") {
        return "PO Number";
      } else {
        return name;
      }
    };

    return (
      <StyledTableRow key={key}>
        <StyledTableCell component="th" scope="row">
          {recordFieldName(fieldName)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {recordFieldName(fieldName) == "Preview URL" ? "-" :

            isVisible == 1 ? (

              <VisibilityIcon
                onClick={visibleHandel}
                data-id={fieldListId}
                data-isvisible={0}
                style={{ color: "#A0A3BD" }}
              />


            ) : (
              <VisibilityOffRoundedIcon
                onClick={visibleHandel}
                data-id={fieldListId}
                data-isvisible={1}
                style={{ color: "#A0A3BD" }}
              />
            )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            style={{ display: "inline-flex", flexDirection: "column" }}
          >
            {recordFieldName(fieldName) == "Preview URL" ? "-" :
              (<><AntSwitch
                onChange={handleChange}
                checked={isRequired === 1}
                disabled={!(isVisible == 1)}
                inputProps={{
                  "aria-label": "ant design",
                  "data-name": "isRequired",
                  "data-id": fieldListId,
                }}
              />
                {/* {["Invoice Number", "Sub Total", "Supplier Name", "Invoice Currency", "Invoice Date"].includes
                  (recordFieldName(fieldName)) ?
                  (<span style={{ color: "#EC7C37" }}>Recommended*</span>) : ""} */}
              </>)}
          </Stack>

        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            style={{ display: "inline-flex" }}
          >
            <AntSwitch
              onChange={handleChange}
              checked={isExport === 1}
              disabled={recordFieldName(fieldName) === "Preview URL" ? "true" : !(isVisible == 1)}
              defaultChecked={recordFieldName(fieldName) == "Preview URL"}
              inputProps={{
                "aria-label": "ant design",
                "data-name": "isExport",
                "data-id": fieldListId,
              }}
            />
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    );
  });

  return (
    <>
      {/* <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
        className={styleclasses.exportgrid}
      >
        <Button
          // onClick={getInstantReport}
          className={styleclasses.notifydownloadbtn}
          variant="contained"
        >
          Generate now
        </Button>
        </Grid> */}
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                className={styleclasses.tableheadrow}
                sx={{ fontWeight: 550 }}
              >
                Invoice Headers
              </StyledTableCell>
              <StyledTableCell
                align="right"
                className={styleclasses.tableheadrow}
              >
                <Typography
                  className={styleclasses.tabletitles}
                  sx={{ fontWeight: 450 }}
                >
                  Display
                  <Tooltip
                    title="This field determines whether the metadata field will be displayed on relevant pages within the application. If enabled, the field will appear in the specified location and format. If disabled, the field will be hidden from view. Note that some fields may be automatically displayed or hidden based on other settings or user permissions."
                    placement="bottom-start"
                    arrow
                    classes={{
                      arrow: styleclasses.arrow,
                      tooltip: styleclasses.tooltip,
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        color: "#A0A3BD",
                        width: "15px",
                        padding: "0px 1px",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                className={styleclasses.tableheadrow}
                sx={{ fontWeight: 550 }}
              >
                <Typography
                  className={styleclasses.tabletitles}
                  sx={{ fontWeight: 450 }}
                >
                 Required 
                  <Tooltip
                    title="This field indicates whether the metadata field must be completed in order to perform certain business processes or comply with legal or regulatory requirements. If enabled, the field will be marked as mandatory and users will not be able to save or submit their work until the field is completed. If disabled, the field will be optional and users may choose to complete it or leave it blank. Note that some fields may be required for certain users or under certain circumstances but not others."
                    placement="bottom-start"
                    arrow
                    classes={{
                      arrow: styleclasses.arrow,
                      tooltip: styleclasses.tooltip,
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        color: "#A0A3BD",
                        width: "15px",
                        padding: "0px 1px",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                className={styleclasses.tableheadrow}
                sx={{ fontWeight: 550 }}
              >
                <Typography
                  className={styleclasses.tabletitles}
                  sx={{ fontWeight: 450 }}
                >
                 Exportable 
                  <Tooltip
                    title="This field indicates whether the metadata field can be included in exports of invoice data. If enabled, the field will be available for selection in export settings and will be included in exported data files. If disabled, the field will not be available for selection and will not be included in exports. Note that some fields may be restricted or protected from export due to privacy or security concerns."
                    placement="bottom-start"
                    arrow
                    classes={{
                      arrow: styleclasses.arrow,
                      tooltip: styleclasses.tooltip,
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        color: "#A0A3BD",
                        width: "15px",
                        padding: "0px 1px",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <SortableContainer
            onSortEnd={changeSortOrderInvoice}
            // useDragHandle
            distance={40}
            helperClass="SortableHelper"
            onSortStart={handleSortStartInvoice}
            disableAutoscroll={false}
          >
            {invoiceList.map((row, index) => (
              <InvoiceSortableItem
                key={`item-${index}`}
                index={index}
                data={row}
              />
            ))}
          </SortableContainer>
        </Table>
      </TableContainer>
    </>
  );
}

const SortableContainer = sortableContainer(({ children }) => {
  return <TableBody style={{ cursor: "grab" }}>{children}</TableBody>;
});
