import React, { useEffect, useState, useRef } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as LineChartTooltip,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Divider,
  MenuItem,
  Menu,
} from "@mui/material";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { debounce, initial } from "underscore";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { CSVLink } from "react-csv";
import { Badge } from "reactstrap";

import { makeStyles } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

import { styled } from "@mui/material/styles";
import Advancemetrixfilter from "../components/Modal/Advancemetrixfilter";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  MONTH_WISE_DEVIATION_URL,
  STANDARD_DEVIATION_URL,
  USER_EMAIL_LIST_URL,
  SUPPLIER_LISTING
} from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import MetricAdvanceInvoiceReceived from "../components/Modal/MetricAdvanceInvoiceReceived";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    borderRadius: "12px !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
  exportgrid: {
    top: "224px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "senderEmail",
    numeric: true,
    disablePadding: false,
    label: "Supplier",
  },
  {
    id: "month",
    numeric: true,
    disablePadding: false,
    label: "Month",
  },
  {
    id: "invoiceTotalCount",
    numeric: true,
    disablePadding: false,
    label: "Invoices Received",
  },
  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Cumulative Total",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={
              headCell.label !== "Invoice Received" ? "custom-border" : ""
            }
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export const Invoicereceivedtable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const { invoiceDataList = [], nameData } = props;
  const [loading, setLoading] = useState(false);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [monthData, setMonthData] = useState({});
  const [requestData, setRequestData] = useState([]);
  const [totalrecords, setTotalRecords] = useState(0);

  const [graphData, setGraphData] = useState([]);
  const [otherMeanValues, setOtherMeanValues] = useState([]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [recordsCsvData, setRecordsCsvData] = useState([]);
  const [dataParams, setDataParams] = useState({
    startDateYear: "",
    offset: "0",
    name: "",
    deviationRange: "",
    value: "",
    limit: "10",
    count: "",
    supplierName: "",
  });
  const [sdev, setSdev] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const CustomToolTip = (props) => {
    const { active, level, otherMeanValues, payload } = props;
    if (level === "0") {
      const l1 = otherMeanValues?.length && otherMeanValues[0]?.split(",");
      const l2 = otherMeanValues?.length && otherMeanValues[1]?.split(",");
      const l3 = otherMeanValues?.length && otherMeanValues[2]?.split(",");
      return active && payload?.length ? (
        <div
          style={{
            background: "#EDE4E3",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          {payload?.map((p, i) => (
            <div
              key={i}
              style={{
                background: "#EDE4E3",
                padding: "0.5rem",
                textAlign: "center",
              }}
            >
              <p>{p?.payload?.supplier}</p>
              <small>
                {p?.payload?.supplier === "-3õ" ? (
                  <>{parseFloat(l3[0])}</>
                ) : (
                  <>
                    {p?.payload?.supplier === "3õ" ? (
                      <>{parseFloat(l3[1])}</>
                    ) : (
                      <>
                        {p?.payload?.supplier === "-2õ" ? (
                          <>{parseFloat(l2[0])}</>
                        ) : (
                          <>
                            {p?.payload?.supplier === "2õ" ? (
                              <>{parseFloat(l2[1])}</>
                            ) : (
                              <>
                                {p?.payload?.supplier === "-1õ" ? (
                                  <>{parseFloat(l1[0])}</>
                                ) : (
                                  <>
                                    {p?.payload?.supplier === "1õ" ? (
                                      <>{parseFloat(l1[1])}</>
                                    ) : (
                                      <>{p?.payload?.mean}</>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </small>
            </div>
          ))}
        </div>
      ) : null;
    }
    if (level === "1") {
      return active && payload?.length ? (
        <div
          style={{
            background: "#EDE4E3",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          {payload?.map((p, i) => (
            <div
              key={i}
              style={{
                background: "#EDE4E3",
                padding: "0.5rem",
                textAlign: "center",
              }}
            >
              <h4>{p?.payload?.supplier}</h4>
              <small>
                {p?.payload?.supplier === "-1õ" ? (
                  <>{parseFloat(otherMeanValues[0])}</>
                ) : (
                  <>
                    {p?.payload?.supplier === "1õ" ? (
                      <>{parseFloat(otherMeanValues[1])}</>
                    ) : (
                      <>{p?.payload?.mean}</>
                    )}
                  </>
                )}
              </small>
            </div>
          ))}
        </div>
      ) : null;
    }
    if (level === "2") {
      return active && payload?.length ? (
        <div
          style={{
            background: "#EDE4E3",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          {payload?.map((p, i) => (
            <div
              key={i}
              style={{
                background: "#EDE4E3",
                padding: "0.5rem",
                textAlign: "center",
              }}
            >
              <p>{p?.payload?.supplier}</p>
              <p>{p?.payload?.label}</p>
              <small>
                {p?.payload?.supplier === "-2õ" ? (
                  <>{parseFloat(otherMeanValues[0])}</>
                ) : (
                  <>
                    {p?.payload?.supplier === "2õ" ? (
                      <>{parseFloat(otherMeanValues[1])}</>
                    ) : (
                      <>{p?.payload?.mean}</>
                    )}
                  </>
                )}
              </small>
            </div>
          ))}
        </div>
      ) : null;
    }
    if (level === "3") {
      return active && payload?.length ? (
        <div
          style={{
            background: "#EDE4E3",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          {payload?.map((p, i) => (
            <div
              key={i}
              style={{
                background: "#EDE4E3",
                padding: "0.5rem",
                textAlign: "center",
              }}
            >
              <h4>{p?.payload?.supplier}</h4>
              <small>
                {p?.payload?.supplier === "-3õ" ? (
                  <>{parseFloat(otherMeanValues[0])}</>
                ) : (
                  <>
                    {p?.payload?.supplier === "3õ" ? (
                      <>{parseFloat(otherMeanValues[1])}</>
                    ) : (
                      <>{p?.payload?.mean}</>
                    )}
                  </>
                )}
              </small>
            </div>
          ))}
        </div>
      ) : null;
    }
    return null;
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const headers = [
    { label: "Supplier", key: "senderEmail" },
    { label: "Invoice Received", key: "invoiceCount" },
    { label: "Standard Deviation", key: "stdDeviationAmount" },
    { label: "Invoice Amount", key: "invoiceAmount" },
  ];

  const getDownloadFileName = () => {
    return "InvoiceReceivedReport";
  };
  const getMonthDeviationAmount = async (dataParams) => {
    // const { startDateYear, numMonth, endto, name, value } = paramValues;

    // setExportLoading(true);
    const config = {
      method: "GET",
      url: MONTH_WISE_DEVIATION_URL,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        // monthFrom: parseInt(numMonth),
        // monthTo: parseInt(endto),
        // year: new Date().getFullYear(),
        // supplierEmail: name,
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            supplierName: dataParams.supplierName,
            deviationRange: dataParams.deviationRange,
            offset: dataParams.offset,
            limit: dataParams.limit,
            count: dataParams.count,
            year: dataParams.startDateYear
              ? dataParams.startDateYear.getFullYear()
              : new Date().getFullYear(),
            // supplierEmail: name,
          })
        ),
        flutterString: "",
      },
    };
    setLoading(true);
    try {
      const response = await API(config);

      //const { status, data = "" } = response.data;
      let l = deCryptFun(response.data);
      
      const { status, data, count } = JSON.parse(l);
      

      if (status == "Success") {
        console.log(data, "datashow");
        setMonthData(data);
        const csvData = [];
        csvData.push(data);
        setShowExport(true);
        setRecordsCsvData(csvData);
        let level_1 = data[0].l1;
        let level_2 = data[0].l2;
        let level_3 = data[0].l3;
        console.log(Object.values(data), "OUTCOME");
        if (Object.values(data)) {
          console.log(level_1, level_2, level_3, "FLOWDATA");
          if (level_1 && level_2 && level_3) {
            setOtherMeanValues([level_1, level_2, level_3]);
            // const splitedValues = (level_1 && level_2  && level_3 )?.split(',')
            // setOtherMeanValues(splitedValues)
            // setSdev(0);
            console.log(data, "data>>>");
            setGraphData([
              {
                supplier: "",
                mean: "0",
              },
              {
                supplier: "-3õ",
                mean: (data && data[0].avarage / 100) * 2.3,
              },
              {
                supplier: "-2õ",
                // mean:level_2.substring(0, level_2.indexOf(",")),
                mean: (data && data[0].avarage / 100) * 13.6,
              },
              {
                supplier: "-1õ",
                // mean: level_1.substring(0, level_1.indexOf(",")),
                mean: (data && data[0].avarage / 100) * 34.1,
              },

              {
                supplier: "Mean",
                mean: data && data[0].avarage,
              },

              {
                supplier: "1õ",
                // mean: level_1.substring(level_1.indexOf(",")+1,level_1.length),
                mean: (data && data[0].avarage / 100) * 34.1,
              },
              {
                supplier: "2õ",
                // mean: level_2.substring(level_2.indexOf(",")+1,level_2.length),
                mean: (data && data[0].avarage / 100) * 13.6,
              },
              {
                supplier: "3õ",
                // mean: level_3.substring(level_3.indexOf(",")+1,level_3.length),
                mean: (data && data[0].avarage / 100) * 2.3,
              },
            ]);
          } else {
            if (data[0].l1) {
              const splitedValues = data[0]?.l1?.split(",");
              setOtherMeanValues(splitedValues);
              console.log("inti");
              setGraphData([
                {
                  supplier: "",
                  mean: "",
                },
                {
                  supplier: "-3õ",
                  mean: "",
                },
                {
                  supplier: "-2õ",
                  mean: "",
                },
                {
                  supplier: "-1õ",
                  mean: (data && data[0].avarage / 100) * 34.1,
                },

                {
                  supplier: "Mean",
                  mean: data && data[0].avarage,
                },

                {
                  supplier: "1õ",
                  mean: (data && data[0].avarage / 100) * 34.1,
                },
                {
                  supplier: "2õ",
                  mean: "",
                },
                {
                  supplier: "3õ",
                  mean: "",
                },
                {
                  supplier: "",
                  mean: "",
                },
              ]);
            } else if (data[0].l2) {
              const splitedValues = data[0]?.l2?.split(",");
              setOtherMeanValues(splitedValues);
              console.log("inti1");
              setGraphData([
                {
                  supplier: "",
                  mean: "",
                },
                {
                  supplier: "-3õ",
                  mean: "",
                },
                {
                  supplier: "-2õ",
                  mean: (data && data[0].avarage / 100) * 13.6,
                },
                // {
                //   supplier: "-1",
                //   mean: data && (data[0].avarage/2),
                // },

                {
                  supplier: "Mean",
                  mean: data && data[0].avarage,
                },
                // {
                //   supplier: "1",
                //   mean:"",
                // },
                {
                  supplier: "2õ",
                  mean: (data && data[0].avarage / 100) * 13.6,
                },
                {
                  supplier: "3õ",
                  mean: "",
                },
                {
                  supplier: "",
                  mean: "",
                },
              ]);
            } else if (data[0].l3) {
              const splitedValues = data[0]?.l3?.split(",");
              setOtherMeanValues(splitedValues);
              console.log("inti2");
              setGraphData([
                {
                  supplier: "",
                  mean: "",
                },
                {
                  supplier: "-3õ",
                  mean: (data && data[0].avarage / 100) * 2.3,
                },

                {
                  supplier: "Mean",
                  mean: data && data[0].avarage,
                  // Label : "mean"
                },

                {
                  supplier: "3õ",
                  mean: (data && data[0].avarage / 100) * 2.3,
                },
                {
                  supplier: "",
                  mean: "",
                },
              ]);
            } else {
              // setSdev(0);
              setGraphData([]);
            }
            // setSdev(data[0].avarage);
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setExportLoading(false);
    }
  };


  let filterInitial = {
    count: "",
    offset: 0,
    companyName: "",
    // requestedName: "",
    requestStatus: "",
    supplierName: "",
    limit: 10,
    // supplierCompanyName: "",
  };

  const [supplierFilter, setsupplierFilter] = useState(filterInitial);


  const requestListing = async (filtervalues) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SUPPLIER_LISTING,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() === "Supplier" ? 0 : getTeamID(),
            offset: filtervalues.offset,
            limit: filtervalues.limit,
            count: filtervalues.count,
            supplierName: filtervalues.name,
            // supplierCompanyName: filtervalues.supplierCompanyName,

            ...filtervalues,
            supplierName:
              getRole() === "Supplier"
                ? getEmail()
                : filtervalues.supplier?.replace("'", "\\'"),
            supplierName: filtervalues.supplierName?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //const { data, status, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, count } = JSON.parse(l);
      console.log("newData??", data);
      setRequestData(
        data.map((item) => {
          return {
            ...item,
            supplierName: item.supplierName || "N/A",
            sentInvite: item.requestStatus,
          };
        })
      );
      setTotalRecords(count);
    } catch (error) {
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const delaySearch1 = useRef(
    debounce((supplierFilter) => requestListing(supplierFilter), 500)
  ).current;

  // const delaySearch = useRef(
  //   debounce((dataParams) => getMonthDeviationAmount(dataParams))
  // ).current;

  useEffect(() => {
    delaySearch1(supplierFilter);
  }, [supplierFilter]);

  const handleFilterSubmit = (filterValues) => {
    const { name, value, startDateYear } = filterValues;
    // setDataParams((v) => ({
    //   ...v,
    //   name: name,
    //   value: value,
    //   startDateYear: startDateYear,
    // }));
    setfilterModal(false);
    getMonthDeviationAmount({
      supplierName: name,
      deviationRange: value,
      startDateYear: startDateYear,
      offset: dataParams.offset,
      limit: dataParams.limit,
    });
  };
  const onResetFilter = () => {
    // setDataParams((v) => ({
    //   ...v,
    //   name: "",
    //   value: "",
    //   startDateYear: "",
    // }));
    setfilterModal(false);
    getMonthDeviationAmount({
      supplierName: "",
      deviationRange: "",
      startDateYear: "",
    });
  };
  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className={styleclasses.exportgrid}
          sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
        >
          {/* <Tooltip
            title="Export as CSV"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          > */}
          <Button
            className={styleclasses.notifydownloadbtn}
            variant="contained"
            id="basic-button"
            aria-controls={openExport ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            onClick={exportClick}
            disabled={
              exportLoading || showexport === false || dataParams.name === ""
            }
          >
            Export
          </Button>
          {/* </Tooltip> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openExport}
            onClose={exportClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <CSVLink
              key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
              data={recordsCsvData}
              headers={headers}
              //ref={ref}
              filename={`${getDownloadFileName()}.csv`}
              target="_blank"
            >
              <MenuItem data-flag="csv" className={styleclasses.exportcsvmenu}>
                Export as CSV
              </MenuItem>
            </CSVLink>
          </Menu>
          <Tooltip
            title="Filter by"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          >
            <IconButton onClick={filterToggle}>
              <FilterListRoundedIcon className={styleclasses.filtercolor} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Grid
              item
              xs={12}
              md={6}
              className={styleclasses.leftside}
              sx={{ padding: "0px 8px 0px 0px " }}
            >
              {monthData && monthData[0]?.invoiceList[0]?.supplierName ? (
                <>
                <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography color="#A0A3BD" style={{ fontSize: "18px" }}>
                      Total Invoices Received:
                    </Typography>
                    <Badge
                      style={{
                        maxWidth: "80px",
                        width: "-webkit-fill-available",
                        marginRight:"450px",

                      }}
                      color={"primary"}
                      
                    >
                      {monthData[0]?.sumOfInvoiceAmount}
                    </Badge>
                  </Box>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer className={styleclasses.tableContainerbg}>
                    <Table
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                        {!loading &&
                         monthData &&
                         Object.values(monthData[0]?.invoiceList).length ===
                         0 && 
                         (
                          <TableRow>
                            <TableCell align="center" colSpan={3}>
                              <Loader />
                            </TableCell>
                          </TableRow>
                        )}
                        {!loading &&
                          Object.values(monthData[0]?.invoiceList).length !== 0 && 
                          monthData.map((item, i) => {
                            return (
                              <>
                                {stableSort(item.invoiceList, getComparator(order, orderBy)).map((list, index) => {
                                  return (
                                    <TableRow hover key={index}>
                                      <TableCell align="center">
                                        {list?.supplierName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {list?.month}
                                      </TableCell>
                                      <TableCell align="center">
                                        {list?.invoiceTotalCount}
                                      </TableCell>
                                      <TableCell align="center">
                                        {list?.invoiceAmount}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            );
                          })}
                            {/* <TableRow>
                              <TableCell align="center" colSpan={3}>
                                <NoRecordFound />
                              </TableCell>
                            </TableRow>
                          )} */}
                        {/* {!loading &&
                          monthData &&
                          Object.values(monthData).length !== 0 && (
                            <TableRow hover key={monthData.senderEmail}>
                              <TableCell align="center">
                                {monthData.senderEmail}
                              </TableCell>
                              <TableCell align="center">
                                {monthData.stdDeviationAmount}
                              </TableCell>
                              <TableCell align="center">
                                {monthData.invoiceCount}
                              </TableCell>
                            </TableRow>
                          )} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      className={styleclasses.paginationrow}
                    >
                      <Typography>
                        Rows Per Page:
                        <Button
                          className={styleclasses.rowsbutton}
                          endIcon={<ArrowDropDownRoundedIcon />}
                        >
                          10
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      className={styleclasses.paginationitem}
                    >
                      <Typography className={styleclasses.paginationnum}>
                        1-10 of 203 items
                        <KeyboardArrowLeftRoundedIcon
                          className={styleclasses.iconpaginationleft}
                        />
                        <ChevronRightRoundedIcon
                          className={styleclasses.iconpaginationright}
                        />
                      </Typography>
                    </Grid>
                  </Grid> */}
                </>
                ) : (monthData[0]?.senderEmail===null?(
                  <Typography>No Record Found</Typography>
              ) : (
                <Typography>Select Supplier to view records</Typography>
              ))}
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: "0px 0px 0px 8px" }}>
              <Grid item xs={12} md={12}>
              {monthData[0]?.invoiceList[0]?.supplierName ? ( 
                 Object.values(monthData).length != 0 ? (
                    graphData.length ? (
                      <LineChart
                        width={600}
                        height={450}
                        data={graphData}
                        margin={{
                          top: 15,
                          right: 20,
                          left: 30,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        {/* <XAxis dataKey="senderEmail"> */}
                        <XAxis dataKey="supplier">
                          <Label value="" offset={0} position="insideBottom" />
                        </XAxis>
                        <YAxis
                          // label={{
                          //   value: "Amount",
                          //   angle: -90,
                          //   position: "insideLeft",
                          // }}
                        />
                        <LineChartTooltip 
                        content={
                          <CustomToolTip
                            otherMeanValues={otherMeanValues}
                            level={dataParams.value}
                          />
                        }/>
                        <Line
                          type="monotone"
                          // dataKey="stdDeviationAmount"
                          dataKey="mean"
                          stroke="#fd7e14"
                          fill="#fd7e14"
                        />
                      </LineChart>
                    ) : (
                      <img
                        src="http://siliconangle.com/files/2013/02/no-data.png"
                        alt="nodata"
                        height="150px"
                        width="150px"
                        className="mt-3"
                      />
                    )
                  ) : (
                    <img
                      src="http://siliconangle.com/files/2013/02/no-data.png"
                      alt="nodata"
                      height="150px"
                      width="150px"
                      className="mt-3"
                    />
                  )
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>{" "}
      <MetricAdvanceInvoiceReceived
        open={filterModal}
        // open={true}
        nameData={nameData}
        dataParams={dataParams}
        handleClose={filterToggle}
        handleFilterSubmit={handleFilterSubmit}
        onResetFilter={onResetFilter}
        supplierData={requestData}
        setDataParams={setDataParams}
      />
    </>
  );
};
