import React, { useState } from "react";
import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputBase,
  NativeSelect,
  Grid,
  TextField,
  Box,
} from "@mui/material";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";

import Autocomplete from "@mui/material/Autocomplete";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
} from "../../comman/function";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

const initialValue = {
  supplierCompanyName: "",
  supplierEmail: "",
  requestStatus: "",
};

const optionList = [
  {
    label: "Approved",
    value: "Accepted",
  },
  {
    label: "Invitation Sent",
    value: "Pending",
  },
  {
    label: "Deactivated",
    value: "Deactivated",
  },
];

const Filtersuppliernew = ({
  open,
  addToggleClose,
  onReset,
  onFilterSubmit,
  supplierFilter,
  tableData,
}) => {
  const styleclasses = useStyles();
  const [edit, setEdit] = useState(false);

  var result = tableData.reduce((unique, o) => {
    if (!unique.some((obj) => obj.supplierName === o.supplierName)) {
      unique.push(o);
    }
    return unique;
  }, []);
  

  const [value, setValue] = React.useState(null);

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      ...initialValue,
      ...supplierFilter,
    },
    onSubmit: (values) => {
      setEdit(false);
      onFilterSubmit(values, value);
    },
  });

  const onResetFilter = () => {
    setEdit(false);
    resetForm({});
    onFilterSubmit(initialValue);
    onReset();
  };
  

  const onChangeHandler = (event, newValue) => {
    setValues((val) => ({
      ...val,
      supplierCompanyName: newValue.supplierName,
    }));
    setEdit(true);
  };

  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={addToggleClose}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Filters
        </BootstrapDialogTitle>
        <Box component={"form"} onSubmit={handleSubmit}>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Supplier Name
              </Typography>
              {/* <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.supplierCompanyName}
                onChange={handleChange}
                name="supplierCompanyName"
                placeholder="Enter Supplier Name"
              /> */}
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box-demo"
                options={result}
                value={{
                  supplierName: values.supplierCompanyName || "",
                }}
                onChange={(event, newValue) => {
                  // setValue(newValue.supplierName);
                  // setValues(newValue.supplierName);
                  // setValues((val) => ({
                  //   ...val,
                  //   supplierCompanyName: newValue.supplierName,
                  // }));
                  onChangeHandler(event, newValue);
                }}
                name="supplierCompanyName"
                getOptionLabel={(option) => option.supplierName}
                renderInput={(params) => (
                  <CssTextField
                    fullWidth
                    id="custom-css-outlined-input"
                    {...params}
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: "7rem",
                  },
                }}
              />
            </Grid>
          </DialogContent>

          <DialogActions>
            <DialogActions>
              <Button
                onClick={onResetFilter}
                className={styleclasses.canceldialog}
              >
                Reset
              </Button>
              <Button
                type="submit "
                onSubmit={handleSubmit}
                className={styleclasses.submitdialog}
                disabled={!edit}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default Filtersuppliernew;
